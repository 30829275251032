export function median(list: number[]): number {
    const N = list.length;
    if (N === 0) {
        return NaN;
    }

    const sorted = [...list].sort((a, b) => a-b);
    const half = Math.floor(N / 2);
    return (N % 2) ? sorted[half] : (sorted[half] + sorted[half-1])/2;
}

export function mean(list: number[]): number {
    return list.reduce((sum, current) => sum + current, 0) / list.length;
}

export function pad(list: number[], min_length: number, fill_value: number): number[] {
    if (list.length >= min_length) {
        return list;
    }
    return list.concat(Array(min_length-list.length).fill(fill_value));
}

export function clip(value: number, min: number, max: number): number {
    if (value < min) {
        return min;
    } else if (value > max) {
        return max;
    }
    return value;
}

export function quantile(values: number[], q: number) {
    const sorted = values.sort((a, b) => a-b);
    const N = sorted.length;
    const qs = [...Array(q).keys()].slice(1).map((n) => Math.floor(n/q*N));
    return qs.map((n) => sorted[n]);
}