export class Vector2 {
    public x: number;
    public y: number;

    constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }

    addVector(b: Vector2): Vector2 {
        return new Vector2(this.x + b.x, this.y + b.y);
    }

    addNumber(a: number): Vector2 {
        return new Vector2(this.x + a, this.y + a);
    }

    multiplyVector(b: Vector2): Vector2 {
        return new Vector2(this.x * b.x, this.y * b.y);
    }

    multiplyNumber(a: number) {
        return new Vector2(this.x * a, this.y * a);
    }

    scalarProduct(b: Vector2): number {
        return this.x * b.x + this.y * b.y;
    }

    elementWiseInverse(): Vector2 {
        return new Vector2(1/this.x, 1/this.y);
    }


    subtractVector(b: Vector2): Vector2 {
        return this.addVector(b.multiplyNumber(-1));
    }
    
    divideElementWise(b: Vector2): Vector2 {
        return this.multiplyVector(b.elementWiseInverse());
    }
}

export class Vector2Collection {
    private _vectorlist: Vector2[];

    constructor(list: Vector2[]) {
        this._vectorlist = list.slice();
    }

    min() {
        return new Vector2(
            Math.min(...this._vectorlist.map(e => e.x)),
            Math.min(...this._vectorlist.map(e => e.y)),
        );
    }

    max() {
        return new Vector2(
            Math.max(...this._vectorlist.map(e => e.x)),
            Math.max(...this._vectorlist.map(e => e.y)),
        );
    }
}

export class Vector2Transformation {
    constructor(
        private shift: Vector2,
        private scale: Vector2
    ) {}

    transform(v: Vector2): Vector2 {
        return v.subtractVector(this.shift).multiplyVector(this.scale);
    }
}