
<!-- Only display if a valid gene has been selected; recreate the view on a gene change. -->
<ng-container *ngIf="gene">
<ng-container *recreateViewOnChange="gene">
<div @detailchange class="card-container">
    <mat-card class="header">
        <mat-card-header>
            <mat-card-title>
                <a href="https://tritrypdb.org/tritrypdb/app/record/gene/{{gene.id}}" target="_blank" title="{{gene.id}} at TriTrypDB">
                    <ng-container *ngIf="gene.symbol === undefined else symbolTitle">
                        {{gene.id}}
                    </ng-container>
                    <ng-template #symbolTitle>
                        {{gene.symbol}} ({{gene.id}})
                    </ng-template>
                </a>
                <p class="subtitle">{{gene.description}}</p>
                <p class="ortholog-list">Selected orthologs:
                    <ng-container *ngFor="let ortholog of displayOrthologs(gene.orthologs);">
                        <a href="https://tritrypdb.org/tritrypdb/app/record/gene/{{ortholog}}">{{ortholog}}</a>&nbsp;
                    </ng-container>
                </p>
            </mat-card-title>
            <a class="close-button" (click)="closeClicked();"><mat-icon>cancel</mat-icon></a>
        </mat-card-header>
    </mat-card>
    <mat-card>
        <mat-card-header>
            <mat-card-title>Deletion phenotyping</mat-card-title>
            <mat-icon svgIcon="delta"></mat-icon>
        </mat-card-header>
        <p *ngIf="gene.barseqData.isEmpty else attempted">
            <ng-container *ngIf="(gene.genotyping === undefined) || (gene.genotyping.CellLineSurvived) else notsurvived">
                No deletion mutant phenotyping data available yet.
            </ng-container>
            <ng-template #notsurvived>
                No deletion mutant could be generated.
            </ng-template>
        </p>
        <ng-template #attempted>
            <ng-container *ngIf="strongPhenotypes(gene) as phenotypes">
                <ng-container *ngIf="phenotypes.length > 0 else nophenotype">
                    <p>Gene deletion {{ genotypingPass(gene.genotyping) ? "causes" : "may cause" }} a fitness phenotype that is:</p>
                    <ul class="phenotyping">
                        <li *ngFor="let phenotype of phenotypes; let last = last">
                            <span [class.tooltip]="phenotype.modifierIndex >= 0">
                                {{phenotype.modifier}}
                                <ng-container *ngIf="phenotype.direction < 0 then negative else positive"></ng-container>
                                <ng-template #negative>deleterious</ng-template>
                                <ng-template #positive>beneficial</ng-template>
                                <span class="tooltiptext">{{strongPhenotypeExplanation(phenotype.modifierIndex)}}</span>
                            </span> in the {{phenotype.assay}}{{ last ? "." : "," }}
                        </li>
                    </ul>
                </ng-container>
                <ng-template #nophenotype>
                    <p>No significant detrimental or beneficial phenotype in any of the assays.</p>
                </ng-template>
            </ng-container>
        </ng-template>
        <ng-container *ngIf="!gene.barseqData.isEmpty">
            <hr/>
            <app-phenotyping-graphs [barseqData]="gene.barseqData"/>
        </ng-container>
    </mat-card>
    <mat-card>
        <mat-card-header>
            <mat-card-title>
                Deletion genotyping
            </mat-card-title>
            <mat-icon fontSet="material-symbols-outlined">genetics</mat-icon>
        </mat-card-header>
        <p class="subtitle">{{genotypingAnalysis(gene)}}</p>
        <ng-container *ngIf="gene.genotyping">
            <hr/>
            <ul class="genotyping">
                <li *ngFor="let details of genotypingDetails(gene.genotyping)" [class]="details.pass ? 'pass' : 'fail'">
                    <mat-icon>{{ details.pass ? 'check' : 'close' }}</mat-icon>
                    <div class="message">
                        <p class="main">{{details.mainMessage}}</p>
                        <p class="sub">{{details.subMessage}}</p>
                    </div>
                </li>
            </ul>
        </ng-container>
    </mat-card>
    <mat-card>
        <mat-card-header>
            <mat-card-title>Microscopy localisation</mat-card-title>
            <mat-icon svgIcon="microscope"></mat-icon>
        </mat-card-header>
        <p>Microscopy of endogenous fluorescent protein tagging at the N and C termini.</p>
        <hr/>
        <ng-container [ngSwitch]="gene.leishtagStyle">
            <ng-container *ngSwitchCase='"yes"'>
                <h3>Localisation:</h3>
                <p>
                    <ng-container *ngFor="let term of gene.leishtagAnnotations.uniquePRONoModifiers(); let last=last;">
                        {{term}}<ng-container *ngIf="!last">, </ng-container>
                    </ng-container>
                </p>
                <app-localisation-diagram [localisationTerms]="gene.leishtagAnnotations"/>
                <a class="leishtaglink" href="http://leishtag.org/?id={{gene.id}}" target="_blank" title="{{gene.id}} at LeishTag.org"><mat-icon>image</mat-icon><div>Browse images</div></a>
            </ng-container>
            <p *ngSwitchCase="'unknown'">No localisation data yet, but imaging and annotation will occur in the future.</p>
            <p *ngSwitchCase="'no'">Not scheduled for tagging as
                <ng-container *ngFor="let ortholog of gene.tBruceiOrthologs; let last = last; let first = first">
                    {{ first ? ' ' : (last ? ' and ' : ', ') }}<a href="http://tryptag.org/?id={{ ortholog }}">{{ ortholog }}</a>
                </ng-container> {{ gene.tBruceiOrthologs.length > 1 ? 'are high sequence similarity orthologs' : 'is a high sequence similarity ortholog' }} in <i>Trypanosoma brucei</i> and {{ gene.tBruceiOrthologs.length > 1 ? 'have' : 'has' }} been imaged during the TrypTag project.
            </p>
        </ng-container>
    </mat-card>
    <mat-card>
        <mat-card-header>
            <mat-card-title>Mass spectrometry localisation</mat-card-title>
            <mat-icon>query_stats</mat-icon>
        </mat-card-header>
        <p>Localisation assignment from mass spectrometry following differential centrifugation fractionation.</p>
        <hr/>
        <ng-container [ngSwitch]="gene.lopitStyle">
            <ng-container *ngSwitchCase='"yes"'>
                <p>Protein was detected and localisation assigned from cofractionation pattern.</p>
                <h3>Localisation:</h3>
                <p>
                    <ng-container *ngFor="let term of gene.lopitAnnotations.entries; let last=last;">
                        {{term}}<ng-container *ngIf="!last">, </ng-container>
                    </ng-container>
                </p>
                <app-lopit-diagram [gene]="gene"/>
            </ng-container>
            <ng-container *ngSwitchCase="'unknown'">
                <p>Protein was detected, but localisation could not be assigned from cofractionation pattern.</p>
                <app-lopit-diagram [gene]="gene"/>
            </ng-container>
            <p *ngSwitchCase="'no'">Protein was not detected by mass spectrometry.</p>
        </ng-container>
    </mat-card>
    <mat-card class="mat-mdc-invisiblecard">
        <p>
            LeishGEM is funded by a Wellcome Trust Collaborative Award [221944/Z/20/Z]. These data are being made available pre-publication, however until the project is complete please adhere to our requirements for data use and citation. For information about the LeishGEM project aims, people and work locations see <a href="https://leishgem.org" title="LeishGEM.org">LeishGEM.org</a>. Email <a href="mailto:leishgem@gmail.com">leishgem&#64;gmail.com</a> with any issues or suggestions.
        </p>
    </mat-card>
</div>
</ng-container>
</ng-container>
