<!-- <div class="help help-list">
    <div class="icon"><mat-icon>keyboard_double_arrow_left</mat-icon></div>
    <span>Select a gene in the list to view its details.</span>
</div>
<div class="help help-search">
    <div class="icon"><mat-icon>keyboard_double_arrow_up</mat-icon></div>
    <span>Search for annotation terms or gene IDs.</span>
</div> -->
<div class="card-container">
    <mat-card class="mat-mdc-invisiblecard title">
        <!-- <object id="logoSvg" height="70" class="svg" type="image/svg+xml" data="http://www.leishgem.org/imageResources/leishgemlogo.svg" style="position: relative; top: 0px; width: 90%;"></object> -->
        <p>
            browse.<wbr>Leish<span style="font-weight: 900">GEM</span>.org
        </p>
        <p style="font-size: 55%">
            LeishGEM Data Browser
        </p>
    </mat-card>
    <mat-card>
        <mat-card-header>
            <mat-card-title>Getting started</mat-card-title>
        </mat-card-header>
        <ul>
            <li>
                Each row in the list is a gene.
            </li>
            <li>
                Use the <strong>search box</strong> to filter the list.
            </li>
            <li>
                <strong>Click on a row</strong> to view a single gene.
            </li>
        </ul>
        <p>
            You can filter by chromosome, gene name or description, or by subcellular localisation. All terms have to be matched.
        </p>
        <p>
            You can also enter multiple <i>Leishmania mexicana</i> gene IDs to list only those genes. Gene IDs from select <i>Leishmania</i> and <i>Trypanosoma</i> species will find the <i>L. mexicana</i> ortholog.
        </p>
        <p>
            Please email us at <a href="mailto:leishgem@gmail.com">leishgem&#64;gmail.com</a> if you have any problems or suggestions.
        </p>
    </mat-card>
    <mat-card>
        <mat-card-header>
            <mat-card-title>Gene list entries</mat-card-title>
        </mat-card-header>
        <p>
            Every <i>Leishmania mexicana</i> protein-coding gene is represented by a row.
        </p>
        <p>
            Every gene will be analysed by deletion mutant fitness phenotype. Localisation of every coded protein will be analysed by microscopy and/or mass spectrometry/LOPIT-DC.
        </p>
    </mat-card>
    <mat-card>
        <mat-card-header>
            <mat-card-title>Colour key</mat-card-title>
        </mat-card-header>
        <p class="center">
            <svg id="legend" viewBox="0 0 380 100">
                <g id="color-legend" style="transform: translate(30px, 15px);">
                    <g class="line" style="transform: translateY(10px);">
                        <rect width="24" height="10" style="fill: var(--color-unknown);"/>
                        <text x="30" y="10">No data available yet</text>
                    </g>
                    <g class="line" style="transform: translateY(30px);">
                        <rect width="24" height="10" style="fill: var(--color-yes);"/>
                        <text x="30" y="10">Positive / data available</text>
                    </g>
                    <g class="line" style="transform: translateY(50px);">
                        <rect width="24" height="10" style="fill: var(--color-no);"/>
                        <text x="30" y="10">Negative / not included</text>
                    </g>
                    <g class="line" style="transform: translateY(70px);">
                        <rect width="24" height="10" style="fill: var(--color-maybe);"/>
                        <text x="30" y="10">Not survived / failed</text>
                    </g>
                </g>
                <g id="fitness-legend" style="transform: translate(230px, 0px);">
                    <defs>
                        <linearGradient id="redblue">
                            <stop offset="0%" stop-color="red"/>
                            <stop offset="50%" stop-color="white"/>
                            <stop offset="100%" stop-color="blue"/>
                        </linearGradient>
                        <linearGradient id="greytransparent" x1="0" x2="0" y1="0" y2="1">
                            <stop offset="0%" stop-color="transparent"/>
                            <stop offset="86%" stop-color="#bbb"/>
                        </linearGradient>
                    </defs>
                    <g style="transform: translate(20px, 20px);">
                        <g style="transform: scale(80);">
                            <rect x="0" y="0" width="1" height="1" fill="url(#redblue)"/>
                            <rect x="0" y="0"  width="1" height="1" fill="url(#greytransparent)"/>    
                            <path d="M 0 0.8 H 1" stroke="black" stroke-width="0.01"/>
                        </g>
                    </g>
                    <text x="60" y="15" class="xlabel" text-anchor="middle" dominant-baseline="auto">- Fitness +</text>
                    <text x="10" y="60" class="ylabel" style="writing-mode: vertical-lr;" text-anchor="middle" dominant-baseline="auto">-log(p)</text>
                </g>
            </svg>
        </p>
        <hr/>
        <p>
            For deletion mutant phenotyping, red means a deleterious fitness phenotype. Blue means a beneficial fitness phenotype. More intense colours indicate stronger phenotypes. Brighter colours mean stronger statistical support. Light gray means no significant change in fitness.
        </p>
        <p>
            Dark gray means that no data is available yet. In most cases, the cell line has not been generated or analysed yet but will in the future. Black means that deletion mutant generation failed.
        </p>
        <hr/>
        <p>
            For genotyping, in the ORF column, turquoise means that the gene open reading frame (ORF) could not be detected by PCR. Magenta means that the gene ORF could still be detected by PCR.
        </p>
        <p>In the DNA column, turquoise means a good quality genomic DNA sample was extracted. Magenta means genomic DNA sample problems.</p>
        <p>Black means that deletion mutant generation failed.</p>
        <hr/>
        <p>
            For localisation, in the tag column, turquoise means that a tagged cell line was generated, imaged, and protein localisation annotated. Magenta means that this protein is similar to a Trypanosoma brucei ortholog so will not be tagged.
        </p>
        <p>
            In the MS (mass spectrometry) column, turquoise means that the protein was detected and assigned a localisation using LOPIT-DC. Dark gray means that the protein was detected but no localisation could be assigned. Magenta means the protein was not detected.
        </p>
    </mat-card>
    <mat-card>
        <mat-card-header>
            <mat-card-title>Example</mat-card-title>
        </mat-card-header>
        <p class="center">
            <svg id="gene-line-illustration" viewBox="-10 0 380 150" preserveAspectRatio="xMidYMid meet">
                <style>
                    g.header {
                        transform: translateY(90px);
                    }
                    g.icons>foreignObject.icon {
                        height: 24px;
                        width: 24px;
                    }
                    g.subicons {
                        transform: translateY(37px);
                    }
                    g.timepoints>text {
                        font-size: 8pt;
                        text-anchor: middle;
                    }
                    g.line {
                        transform: translateY(130px);
                    }
                    g.assay>rect {
                        width: 25.5px;
                        height: 10px;
                        y: 0px;
                    }
                    g.annotations>g.assaynames {
                        transform: translate(0px, 0px);
                    }
                    g.annotations>g.assaynames>g>text>tspan {
                        text-anchor: middle;
                        font-size: 10pt;
                    }
                    #promastigote-growth {
                        transform: translate(0px, 20px);
                    }
                    #amastigote-growth {
                        transform: translate(77px, 48px);
                    }
                    #macrophage-infection {
                        transform: translate(129px, 20px);
                    }
                    #footpad-infection {
                        transform: translate(181px, 48px);
                    }
                    #genotyping {
                        transform: translate(258px, 20px);
                    }
                    #localisation {
                        transform: translate(310px, 48px);
                    }
                </style>
                <g class="header">
                    <g class="icons">
                        <foreignObject x="25.5" class="icon"><mat-icon color="accent" svgIcon="promastigote"></mat-icon></foreignObject>
                        <foreignObject x="90" class="icon"><mat-icon color="accent" svgIcon="amastigote"></mat-icon></foreignObject>
                        <foreignObject x="142" class="icon"><mat-icon color="accent" svgIcon="macrophage"></mat-icon></foreignObject>
                        <foreignObject x="206.5" class="icon"><mat-icon color="accent" svgIcon="mouse"></mat-icon></foreignObject>
                        <foreignObject x="271" class="icon"><mat-icon color="accent" svgIcon="dna"></mat-icon></foreignObject>
                        <foreignObject x="323" class="icon"><mat-icon color="accent" svgIcon="microscope"></mat-icon></foreignObject>
                    </g>
                    <g class="subicons">
                        <g class="timepoints">
                            <text x="12">1d</text>
                            <text x="36">2d</text>
                            <text x="60">3d</text>
                        </g>
                        <g class="timepoints" style="transform: translateX(77px);">
                            <text x="12">1d</text>
                            <text x="36">3d</text>
                        </g>
                        <g class="timepoints" style="transform: translateX(129px);">
                            <text x="12">1d</text>
                            <text x="36">3d</text>
                        </g>
                        <g class="timepoints" style="transform: translateX(181px);">
                            <text x="12">3d</text>
                            <text x="36">3w</text>
                            <text x="60">6w</text>
                        </g>
                        <g class="timepoints" style="transform: translateX(258px);">
                            <text x="12">ORF</text>
                            <text x="36">DNA</text>
                        </g>
                        <g class="timepoints" style="transform: translateX(310px);">
                            <text x="12">Tag</text>
                            <text x="36">MS</text>
                        </g>
                    </g>
                </g>
                <g class="line">
                    <g class="assay">
                        <rect style="fill: var(--color-unknown);"/>
                        <rect x="25" style="fill: var(--color-unknown);"/>
                        <rect x="50" style="fill: var(--color-unknown);"/>
                    </g>
                    <g class="assay" style="transform: translateX(77px);">
                        <rect x="0" style="fill: #ecc;"/>
                        <rect x="25" style="fill: #f00;"/>
                    </g>
                    <g class="assay" style="transform: translateX(129px);">
                        <rect x="0" style="fill: rgb(187, 187, 187);"/>
                        <rect x="25" style="fill: rgb(187, 187, 187);"/>
                    </g>
                    <g class="assay" style="transform: translateX(181px);">
                        <rect x="0" style="fill: rgb(187, 187, 187);"/>
                        <rect x="25" style="fill: #aaf"/>
                        <rect x="50" style="fill: #00f;"/>
                    </g>
                    <g class="assay" style="transform: translateX(258px);">
                        <rect x="0" style="fill: var(--color-yes);"/>
                        <rect x="25" style="fill: var(--color-yes);"/>
                    </g>
                    <g class="assay" style="transform: translateX(310px);">
                        <rect x="0" style="fill: var(--color-no);"/>
                        <rect x="25" style="fill: var(--color-yes);"/>
                    </g>
                </g>
                <g class="annotations">
                    <g class="assaynames">
                        <g id="promastigote-growth">
                            <text x="0" y="0">
                                <tspan x="36">Promastigote</tspan>
                                <tspan x="36" dy="1em">growth</tspan>
                            </text>
                            <foreignObject x="24" y="1em" width="24" height="24"><mat-icon>arrow_downward</mat-icon></foreignObject>
                        </g>
                        <g id="amastigote-growth">
                            <text x="0" y="0">
                                <tspan x="25">Amastigote</tspan>
                                <tspan x="25" dy="1em">growth</tspan>
                            </text>
                            <foreignObject x="13" y="1em" width="24" height="24"><mat-icon>arrow_downward</mat-icon></foreignObject>
                        </g>
                        <g id="macrophage-infection">
                            <text x="0" y="0">
                                <tspan x="25">Macrophage</tspan>
                                <tspan x="25" dy="1em">infection</tspan>
                            </text>
                            <foreignObject x="13" y="1em" width="24" height="24"><mat-icon>arrow_downward</mat-icon></foreignObject>
                        </g>
                        <g id="footpad-infection">
                            <text x="0" y="0">
                                <tspan x="36">Mouse footpad</tspan>
                                <tspan x="36" dy="1em">infection</tspan>
                            </text>
                            <foreignObject x="24" y="1em" width="24" height="24"><mat-icon>arrow_downward</mat-icon></foreignObject>
                        </g>
                        <g id="genotyping">
                            <text x="0" y="0">
                                <tspan x="25" dy="1em">Genotyping</tspan>
                            </text>
                            <foreignObject x="13" y="1em" width="24" height="24"><mat-icon>arrow_downward</mat-icon></foreignObject>
                        </g>
                        <g id="localisation">
                            <text x="0" y="0">
                                <tspan x="25" dy="1em">Localisation</tspan>
                            </text>
                            <foreignObject x="13" y="1em" width="24" height="24"><mat-icon>arrow_downward</mat-icon></foreignObject>
                        </g>
                    </g>
                </g>
            </svg>
        </p>
        <hr/>
        <p>
            For the deletion mutant:
        </p>
        <ul>
            <li>There is no data yet for the promastigote growth assay.</li>
            <li>As axenic amastigotes, there was a mildly deleterious fitness phenotype at 24 hours and an extremely deleterious fitness phenotype at 72 hours after differentiation from promastigotes.</li>
            <li>In infected macrophages, amastigotes had no significant fitness defect or benefit.</li>
            <li>In mouse footpad infections, there was no significant fitness defect or benefit 3 days after infection. 3 weeks after infection there was a mildly beneficial fitness phenotype, increasing to extremely beneficial by 6 weeks.</li>
            <li>Genotyping confirmed that the open reading frame (ORF) could not be detected by PCR, and that genomic DNA was successfully extracted.</li>
        </ul>
        <p>
            For protein subcellular localisation:
        </p>
        <ul>
            <li>Tagging will not be carried out.</li>
            <li>The protein was detected and localised to cell compartment using mass spectroscopy / LOPIT-DC.</li>
        </ul>
    </mat-card>
    <mat-card class="mat-mdc-invisiblecard">
        <p>
            LeishGEM is funded by a Wellcome Trust Collaborative Award [221944/Z/20/Z]. These data are being made available pre-publication, however until the project is complete please adhere to our requirements for data use and citation. For information about the LeishGEM project aims, people and work locations see <a href="https://leishgem.org" title="LeishGEM.org">LeishGEM.org</a>. Email <a href="mailto:leishgem@gmail.com">leishgem&#64;gmail.com</a> with any issues or suggestions.
        </p>
    </mat-card>
<!-- 
    <h1>About LeishGEM</h1>
    <mat-card>
        <mat-card-header>
            <mat-card-title>The project</mat-card-title>
        </mat-card-header>
        <p>
            LeishGEM (the&nbsp;<b>Leish</b>mania&nbsp;<b>ge</b>netic&nbsp;<b>m</b>odification project) uses high throughput genetic tools to understand how&nbsp;<i>Leishmania</i>&nbsp;parasites are such successful pathogens.
        </p>
        <p>
            This collaborative project has two genome-wide reverse genetics screens as a foundation, followed up with cutting edge molecular biology, cell biology and data science.
        </p>
        <hr/>
        <p>
            LeishGEM&nbsp;determines which proteins are needed for <i>Leishmania</i>&nbsp;parasites&nbsp;to&nbsp;progress&nbsp;through their life cycle and survive in their hosts, which stages they're important for and where they are found in the cell.
        </p>
        <p>
            This approach does not just find important proteins, it finds <b>the most</b>&nbsp;important proteins. We assemble this knowledge to find&nbsp;the most important pathways for pathogenicity.
        </p>
        <hr/>
        <p>
            The two genome-wide screens which are the LeishGEM foundations are community resources:&nbsp;<b>Deletion bar-seq</b>&nbsp;and&nbsp;<b>LeishTag</b>.&nbsp;These will transform our&nbsp;understanding <i>Leishmania</i> molecular cell biology.&nbsp;
        </p>
    </mat-card>
    <mat-card>
        <mat-card-header>
            <mat-card-title>Deletion bar-seq</mat-card-title>
        </mat-card-header>
        <p>
            This sub-project generates deletion mutants for every&nbsp;<i>Leishmania</i>&nbsp;protein-coding gene, with each mutant carrying a unique genetic barcode. We put pools of these mutants through series of assays in host organisms, host cells or in vitro imitations of host environments, then sequence the barcodes in the population (bar-seq) to determine which genes are required under each condition. It builds on the success of our previous, smaller, bar-seq experiments to understand&nbsp;<i>Leishmania</i>&nbsp;molecular cell biology (<a href="https://journals.plos.org/plospathogens/article?id=10.1371/journal.ppat.1007828">Beneke et al. 2019</a>, <a href="https://www.nature.com/articles/s41467-021-21360-8">Baker et al. 2021</a>).
        </p>
        <hr/>
        <p>
            The deletion bar-seq fitness data is made available as it is generated on this website. This will become a genome-wide database of quantitative parasite fitness defects across multiple <i>in vitro</i> and <i>in vivo</i> conditions caused by deletion of a gene. The approximately 30 pooled populations of roguhly 300 barcoded deletion mutants, together covering the entire genome, will be cryopreserved as a resource for the future.
        </p>
    </mat-card>
    <mat-card>
        <mat-card-header>
            <mat-card-title>LeishTag</mat-card-title>
        </mat-card-header>
        <p>
            LeishTag determines the subcellular localisation of&nbsp;<i>Leishmania</i>&nbsp;proteins, prioritising proteins which appear to be recent evolutionary adaptations in <i>Leishmania</i> or identified as important for pathogenicity by deletion bar-seq. This is being supplemented with LOPIT-DC evidence. It builds on to the success of TrypTag, carried out in the related parasite&nbsp;<i>Trypanosoma brucei</i>&nbsp;and prioritises genes not conserved in&nbsp;<i>T. brucei</i>.
        </p>
        <hr/>
        <p>
            The LeishTag data is being made available as it is generated on <a href="http://leishtag.org">LeishTag.org</a>. This will be a database of microscope images of the promastigote and amastigote life cycle stages and annotations of the protein subcellular localisation.
        </p>
    </mat-card>
    <mat-card>
        <mat-card-header>
            <mat-card-title>LOPIT</mat-card-title>
        </mat-card-header>
        <p>
            LOPIT determines the subcellular localisation of&nbsp;<i>Leishmania</i>&nbsp;proteins using mass spectrometry.
        </p>
    </mat-card>
    <h1>Locations</h1>
    <mat-card>
        <mat-card-header>
            <mat-card-title>Bern</mat-card-title>
        </mat-card-header>
        <p>
            <a href="https://gluenzlab.net/">Gluenz lab</a> (Institute of Cell Biology, University of Bern)
        </p>
        <p>
            Eva Gluenz's group main focus is on the cell biology of <i>Leishmania</i> mutants <i>in vitro</i>. Bar-seq deletion mutant generation and <i>in vitro</i> phenotyping is being carried out here.
        </p>
    </mat-card>
    <mat-card>
        <mat-card-header>
            <mat-card-title>York</mat-card-title>
        </mat-card-header>
        <p>
            <a href="http://www.mottramlab.org">Mottram lab</a> (in the York Biomedical Research Institute, University of York)
        </p>
        <p>
            Work in Jeremy Mottram's group focuses on understanding the interaction of the <i>Leishmania</i> parasite with its host. Bar-seq deletion <i>in vitro</i> and <i>in vivo</i> phenotyping is being carried out here, as is the LOPIT-DC contribution to LeishTag.
        </p>
    </mat-card>
    <mat-card>
        <mat-card-header>
            <mat-card-title>Oxford</mat-card-title>
        </mat-card-header>
        <p>
            <a href="http://www.brookes.ac.uk/bms/about/staff/?wid=&amp;op=full&amp;uid=p0084976">Sunter lab</a> (Department of Biological and Medical Sciences, Oxford Brookes University)<br>
            <a href="http://wheelerlab.net">Wheeler lab</a> (Peter Medawar Building for Pathogen Resarch, University of Oxford)
        </p>
        <p>
            The Oxford-based research groups focus on subcellular protein localisation. Jack Sunter's group focus on microscopy of <i>Leishmania</i> and hosts the LeishTag sub-project. Richard Wheeler's group focuses on computational analysis and integration of the different data types from the different LeishGEM sites.
        </p>
    </mat-card>
    <mat-card>
        <mat-card-header>
            <mat-card-title>Other collaborators</mat-card-title>
        </mat-card-header>
        <p>
            Collaborators include Petr Volf (Charles University, Prague) for work in the sandfly vector and Samuel Dean (University of Warwick) for his expertise from TrypTag.
        </p>
    </mat-card>
</div> -->
