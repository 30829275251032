import { Gene, GeneMap } from './gene'

class GeneSet {
    ids: Set<string>;
    private geneMap: GeneMap;

    constructor(geneMap: GeneMap, genes: Gene[] = []) {
        this.ids = new Set<string>(genes.map((gene) => gene.id));
        this.geneMap = geneMap;
    }

    add(gene: Gene) {
        if (!(gene.id in this.geneMap)) throw Error("Gene ID is not in database!");
        if (!this.ids.has(gene.id)) {
            this.ids.add(gene.id);
        }
    }

    static union(...sets: GeneSet[]): GeneSet {
        const geneSet = new GeneSet(sets[0].geneMap);
        for (const set of sets) {
            for (const id of set.ids) {
                geneSet.ids.add(id);
            }
        }
        return geneSet;
    }

    static difference(a: GeneSet, b: GeneSet): GeneSet {
        const geneSet = new GeneSet(a.geneMap);
        for (const id of a.ids) {
            if (!b.ids.has(id)) {
                geneSet.ids.add(id);
            }
        }
        return geneSet;
    }

    static intersection(a: GeneSet, b: GeneSet): GeneSet {
        const geneSet = new GeneSet(a.geneMap);
        for (const id of a.ids) {
            if (b.ids.has(id)) {
                geneSet.ids.add(id);
            }
        }
        return geneSet;
    }

    get size(): number {
        return this.ids.size;
    }
}

class SearchTerm {
    term: string;
    genes: GeneSet;

    deserialize(term: string, geneids : string[], genes: GeneMap) {
        this.term = term;
        this.genes = new GeneSet(genes);
        for (const geneid of geneids) {
            this.genes.add(genes[geneid]);
        }
        return this;
    }
}

export interface SearchTermMap {
    [terms: string] : SearchTerm
}

export class SearchIndex {
    terms : SearchTermMap;
    private genes : GeneMap;

    get searchTerms(): string[] {
        return Object.keys(this.terms).concat(Object.keys(this.genes));
    }

    search(queries: string[]): Gene[] {
        if (queries.length == 0) {
            return Object.values(this.genes);
        }
        const matchingSets: GeneSet[] = [];
        const matchingGenes: GeneSet = new GeneSet(this.genes);
        const matchingDescriptions: GeneSet[] = [];
        for (const query of queries) {
            const queryMatchingSets: GeneSet[] = [];
            const queryValue = query.toLowerCase();
            for (const term of Object.values(this.terms)) {
                if (term.term.toLowerCase().includes(queryValue)) {
                    queryMatchingSets.push(term.genes);
                }
            }
            if (queryMatchingSets.length > 0) {
                matchingSets.push(GeneSet.union(...queryMatchingSets));
            }

            const queryMatchingDescriptions = new GeneSet(this.genes);
            for (const gene of Object.values(this.genes)) {
                if (gene.id.toLowerCase().includes(queryValue)) {
                    matchingGenes.add(gene);
                }
                if (gene.description.toLocaleLowerCase().includes(queryValue)) {
                    queryMatchingDescriptions.add(gene);
                }
            }
            matchingDescriptions.push(queryMatchingDescriptions);
        }

        let intersectionOfSets: GeneSet;
        if (matchingSets.length == 0) {
            intersectionOfSets = new GeneSet(this.genes);
        } else {
            intersectionOfSets = matchingSets[0];
            for (const set of matchingSets.slice(1)) {
                intersectionOfSets = GeneSet.intersection(intersectionOfSets, set);
            }
        }

        let intersectionOfDescriptions: GeneSet;
        if (matchingDescriptions.length == 0) {
            intersectionOfDescriptions = new GeneSet(this.genes);
        } else {
            intersectionOfDescriptions = matchingDescriptions[0];
            for (const set of matchingDescriptions.slice(1)) {
                intersectionOfDescriptions = GeneSet.intersection(intersectionOfDescriptions, set);
            }
        }

        let union = GeneSet.union(intersectionOfSets, intersectionOfDescriptions, matchingGenes);
        return [...union.ids].map((id) => this.genes[id]);
    }

    addGeneToTerm(term: string, gene: Gene) {
        if (!this.terms.hasOwnProperty(term)) {
            const termObj = new SearchTerm();
            termObj.term = term;
            termObj.genes = new GeneSet(this.genes, [gene]);
            this.terms[term] = termObj;
        } else {
            this.terms[term].genes.add(gene);
        }
    }

    constructor(genes: GeneMap) {
        this.terms = {};
        this.genes = genes;
        for (const gene of Object.values(genes)) {
            this.addGeneToTerm(gene.contig, gene);
            if (gene.symbol !== undefined) {
                this.addGeneToTerm(gene.symbol, gene);
            }
            for (const term of gene.leishtagAnnotations.uniqueTermsNoModifiers()) {
                this.addGeneToTerm(term, gene); // Should extend this to amastigote terms
            }
            for (const term of gene.lopitAnnotations.entries) {
                this.addGeneToTerm(term, gene);
            }
            for (const orthologList of Object.values(gene.orthologs)) {
                for (const ortholog of orthologList) {  
                    this.addGeneToTerm(ortholog, gene);
                }
            }
        }
        return this;
    }
}