import { Injectable, OnDestroy } from '@angular/core';
import { DataLoaderService } from './data-loader.service';
import { BarSeqData, GeneMap, Timepoint } from './gene';
import { quantile } from './statistics';
import { Observable, ReplaySubject, Subject, map, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService implements OnDestroy {
  private readonly destroyed$ = new Subject<void>();
  private _quartiles: ReplaySubject<Map<string, Map<number, number[]>>> = new ReplaySubject<Map<string, Map<number, number[]>>>(1); 

  constructor(
    private dataLoaderService: DataLoaderService,
  ) {
    this.dataLoaderService.genes
      .pipe(
        map(this.collectTimePoints),
        takeUntil(this.destroyed$)
      ).subscribe(this._quartiles);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  get quartiles(): Observable<Map<string, Map<number, number[]>>> {
    return this._quartiles.asObservable();
  }

  collectTimePoints(geneMap: GeneMap): Map<string, Map<number, number[]>> {
    const genes = Object.values(geneMap);

    const timepointMap: Map<string,Map<number,Timepoint[]>> = new Map<string,Map<number,Timepoint[]>>()
    BarSeqData.AssayTimepoints.forEach((hours, assay) => {
      const hoursMap: Map<number, Timepoint[]> = new Map<number, Timepoint[]>();
      hours.forEach((hour) => { hoursMap.set(hour, [])});
      timepointMap.set(assay, hoursMap);
    });

    for (const gene of genes) {
      for (const [assayName, assay] of Object.entries(gene.barseqData.assays)) {
        for (const [hour, timepoint] of Object.entries(assay.timepoints)) {
          if (!timepoint.isEmpty) {
            timepointMap.get(assayName)?.get(Number(hour))?.push(timepoint);
          }
        }
      }
    }

    const quartileMap: Map<string, Map<number, number[]>> = new Map<string, Map<number, number[]>>();
    timepointMap.forEach((assays, assayName) => {
      const quartiles = new Map<number, number[]>();
      quartileMap.set(assayName, quartiles);
      assays.forEach((timepoint, hours) => {
        quartiles.set(hours, quantile(timepoint.filter((tp) => (tp.pValue < 0.05) && (tp.effectSize < 0.5)).map((tp) => tp.effectSize), 4));
      });
    });

    return quartileMap;
  }
}