import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import { environment } from './environments/environment';

// Pull up the appropriate Umami analytics for the environment (none for development, )
const analytics = environment.analytics;
if (analytics.src != '') {
  const script = document.createElement('script');
  script.setAttribute('defer', 'defer');
  script.setAttribute('data-website-id', analytics.id);
  script.setAttribute('src', analytics.src);
  document.body.appendChild(script);
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
