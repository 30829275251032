import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { Gene, Genotyping, Orthologs } from '../gene';
import { StatisticsService } from '../statistics.service';
import { SelectedService } from '../selected.service';
import { BreakpointService } from '../breakpoint.service';
import { trigger, style, animate, transition, keyframes } from '@angular/animations';

const ORTHOLOG_DISPLAY_ORGANISMS = [
  'Leishmania infantum JPCM5',
//  'Leishmania braziliensis MHOM/BR/75/M2904',
  'Leishmania donovani BPK282A1',
  'Leishmania major strain Friedlin',
  'Trypanosoma brucei brucei TREU927',
  'Trypanosoma cruzi CL Brener Esmeraldo-like'
]
@Component({
  selector: 'app-gene-detailview',
  templateUrl: './gene-detailview.component.html',
  styleUrls: ['./gene-detailview.component.scss'],
  animations: [
    trigger("detailchange", [
      transition(":enter", [   // Fade in of a new detail view
        animate(300,
          keyframes([
            style({opacity: 0}),
            style({opacity: 1}),
          ])
        ),
      ]),
      transition(":leave", [   // Fade out if detail view is closed
        animate(300,
          keyframes([
            style({opacity: 1}),
            style({opacity: 0}),
          ])
        ),
      ]),
    ]),
    trigger("change", [
      transition("* => *", [  // Fade out and in if gene is changed
        animate(600,
          keyframes([
            style({opacity: 1}),
            style({opacity: 0}),
            style({opacity: 1}),
          ])),
      ]),
    ])
  ]
})
export class GeneDetailviewComponent implements OnInit, OnDestroy {
  // Single-trigger subject to avoid memory leaks upon destruction
  private readonly destroyed$ = new Subject<void>()

  // Gene object is the only input of the detail view
  @Input() gene: Gene | null | undefined;

  // Make breakpoint small screen subject available in template
  public smallScreen = this.breakpointService.smallScreen;

  // This holds the quartiles for the descriptive sentence below.
  private quartiles: Map<string, Map<number, number[]>>;

  // Assay names - this should really be a static map somewhere else...
  private assayNames: Map<string, string> = new Map([
    ["PRO", "promastigote"],
    ["AXA", "amastigote"],
    ["IMAC", "macrophage"],
    ["FP", "mouse footpad"],
  ]);

  constructor(
    private selectedService: SelectedService, // We need this to unselect the gene when the close button is clicked
    private statisticsService: StatisticsService, // For the quartiles
    private breakpointService: BreakpointService, // Screen size change detection
  ) {}

  public ngOnInit(): void {
    this.statisticsService.quartiles
      .pipe(takeUntil(this.destroyed$))
      .subscribe((quartiles => this.quartiles = quartiles)); // This gets the new quartiles from the service if a new gene is selected
  }

  // Make sure we unsubscribe from everything on destruction
  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  displayOrthologs(orthologs: Orthologs): string[] {
    const dOrthologs: string[] = [];
    for (const [organism, orthologId] of Object.entries(orthologs)) {
      if (ORTHOLOG_DISPLAY_ORGANISMS.includes(organism)) {
        dOrthologs.push(...orthologId);
      }
    }
    console.log(dOrthologs);
    return dOrthologs
  }

  // Calculates which quartile word index a time point corresponds to
  quartilesToWords(assay: string, hours: string, effSize: number): number {
    const quartiles = this.quartiles.get(assay)?.get(Number(hours))!;
    for (var i = 0; i < 3; i++) {
      if (effSize < quartiles[i]) {
        return i;
      }
    }
    return 3;
  }

  // Constructs a list of strong phenotype descriptions to be used in the HTML template
  strongPhenotypes(gene: Gene): Phenotype[] {
    const words: string[] = ["extremely", "severely", "strongly", "mildly"];
    const phenotypes: Phenotype[] = [];
    Object.entries(gene.barseqData.assays).forEach((item) => {
      const tpList = item[1].timepointList;
      if (tpList.some((tp) => tp.pValue < 0.05)) {
        if (tpList.some((tp) => tp.effectSize < 0.5)) {
          const modifierIndex = Math.min.apply(Math, (Object.entries(item[1].timepoints).map((entry) => this.quartilesToWords(item[0], entry[0], entry[1].effectSize))));
          phenotypes.push({
            direction: -1,
            modifierIndex: modifierIndex,
            modifier: words[modifierIndex],
            assay: this.assayNames.get(item[0])!,
          });
        }
        if (tpList.some((tp) => tp.effectSize > 2)) {
          phenotypes.push({
            direction: 1,
            modifierIndex: -1,
            modifier: "",
            assay: this.assayNames.get(item[0])!,
          });
        }
      }
    });
    return phenotypes;
  }

  // Returns an explanation for the given quartile
  strongPhenotypeExplanation(quartile: number): string {
    if (quartile == 0) {
      return "Above the 75th percentile of significantly deleterious phenotypes, ranked by increasing severity."
    } else if (quartile == 1) {
      return "Between the 50th and the 75th percentile of significantly deleterious phenotypes, ranked by increasing severity."
    } else if (quartile == 2) {
      return "Between the 25th and the 50th percentile of significantly deleterious phenotypes, ranked by increasing severity."
    } else if (quartile == 3) {
      return "Below the 25th percentile of significantly deleterious phenotypes, ranked by increasing severity."
    }
    return "";
  }

  // Close the gene detail page (this actually deselects the gene, which causes the detail page to be closed. This should be changed.)
  closeClicked() {
    this.selectedService.selectById("unselect");
  }

  // Returns a sentence summarising the genotyping results for the given gene
  genotypingAnalysis(gene: Gene): string {
    if (gene.genotyping === undefined) {
      if (gene.isControlMutant) {
        return "This knockout cell line is a control, therefore no genotyping data is available.";
      }
      return "No attempt was made to generate this cell line yet.";
    }
    const genotyping = gene.genotyping;
    if (!genotyping.CellLineSurvived) {
      return "Deletion cell line generation unsuccessful.";
    }
    const hasPhenotype = this.strongPhenotypes(gene).length > 0;
    var phrase: string
    if (!(genotyping.DrugMarker && genotyping.ParentalORFDetected)) {
      if (hasPhenotype) {
        phrase = "Technical problem prevented deletion cell line genotyping, but the cell line still had a significant fitness change in at least one assay.";
      } else {
        phrase = "Technical problem prevented deletion cell line genotyping.";
      }
    } else if (genotyping.ORFDeleted) {
      if (hasPhenotype) {
        phrase = "Deletion cell line was successfully generated and had a significant fitness change in at least one assay.";
      } else {
        phrase = "Deletion cell line was successfully generated.";
      }
    } else {
      if (hasPhenotype) {
        phrase = "Complete deletion not confirmed, but the cell line still had a significant fitness change in at least one assay.";
      } else {
        phrase = "Complete deletion not confirmed.";
      }
    }
    return phrase;
  }

  // Returns a list of genotyping details
  genotypingDetails(genotyping: Genotyping): GenotypingDetail[] {
    const results: GenotypingDetail[] = [];

    // Survival
    if (genotyping.CellLineSurvived) {
      results.push({
        pass: true,
        mainMessage: "Cell line resistant to both selection drugs.",
        subMessage: "Both selection drug resistance genes are expressed.",
      })
    } else {
      results.push({
        pass: false,
        mainMessage: "No cells survived drug selection.",
        subMessage: "Transfection failed (selection drug resistance genes did not integrate) or gene deletion is lethal.",
      })

      // If the cell line did not survive, we don't report anything else.
      return results;
    }

    // Check for DNA present
    if (genotyping.DrugMarker) {
      results.push({
        pass: true,
        mainMessage: "Genomic DNA extracted successfully.",
        subMessage: "Successful control detecting selection drug resistance gene in deletion cell line genomic DNA.",
      })
    } else {
      results.push({
        pass: false,
        mainMessage: "Genomic DNA not extracted successfully.",
        subMessage: "Failed control detecting selection drug resistance gene in deletion cell line genomic DNA."
      })
    }

    // Check for parental target ORF
    if (genotyping.ParentalORFDetected) {
      results.push({
        pass: true,
        mainMessage: "Successful detection of target gene in control.",
        subMessage: "Target gene detectable by PCR in parental cell line genomic DNA.",
      })
      // Check for parental target ORF
      if (genotyping.ORFDeleted) {
        if (genotyping.DrugMarker) {
          results.push({
            pass: true,
            mainMessage: "Deletion cell line lacks the target gene.",
            subMessage: "Target gene is not detectable in deletion cell line genomic DNA.",
          })
        } else {
          results.push({
            pass: false,
            mainMessage: "Deletion target gene not detectable by PCR.",
            subMessage: "Failed control detecting target gene in parental cell line genomic DNA.",
          })
        }
      } else {
        results.push({
          pass: false,
          mainMessage: "Deletion cell line does not completely lack the target gene.",
          subMessage: "Target gene is detectable in deletion cell line genomic DNA. At least ~1% of the population still possesses a copy of the gene.",
        })
        // TODO: Add polyploidy, gene array or similar gene information if available.
        // TODO: Add band of unexpected size info once available (gel analysis). 
      }
    } else {
      results.push({
        pass: false,
        mainMessage: "Deletion target gene not detectable by PCR.",
        subMessage: "Failed control detecting target gene in parental cell line genomic DNA."
      })
    }

    return results;
  }

  genotypingPass(genotyping: Genotyping | undefined): boolean {
    if (genotyping === undefined) {
      return false;
    }

    for (const detail of this.genotypingDetails(genotyping)) {
      if (!detail.pass) {
        return false;
      }
    }
    return true;
  }
}

interface Phenotype {
  direction: 1 | -1;
  modifierIndex: number;
  modifier: string;
  assay: string;
}

interface GenotypingDetail {
  pass: boolean
  mainMessage: string;
  subMessage: string;
}
