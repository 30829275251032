<h2 mat-dialog-title>Terms for data usage</h2>
<mat-dialog-content>
    <p>The LeishGEM data browser provides access to deletion mutant fitness phenotype and the subcellular localisation of all proteins encoded in the <i>Leishmania</i> genome. This data is being made available to the community as it is generated. Until the project is complete, data usage terms are based on the Fort Lauderdale agreement, an agreement reached for pre-publication usage of genome data.</p>

    <p>Data on browse.LeishGEM.org is available immediately and free to use with acknowledgement. However until the project is complete:</p>
    <ul>
        <li>You must contact us before scientific publication or commercial application of research informed by this data. Please email: <a href="mailto:richard.wheeler@ndm.ox.ac.uk">Richard Wheeler</a></li>
        <li>One of the LeishGEM team will work with you to share our raw data on the proteins of interest, help with advanced searches for cohorts or patterns, etc.</li>
        <li>For publications concerning one or two genes we will typically request acknowledgement and citation of this website as a data resource.</li>
        <li>For publications concerning more than two genes we reserve the right to have members of the LeishGEM team as co-authors, with the rights this entails. This will typically be the one person you collaborate with but, for example for extremely large cohorts of genes, may include others.</li>
        <li>Commercial applications will be considered on an individual basis.</li>
    </ul>

    <p>To use this resource you must agree to these terms. This website uses of cookies to track your preferences and website usage statistics.</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Accept</button>
</mat-dialog-actions>