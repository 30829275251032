import { Component } from '@angular/core';
import { MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-dialog-agree-terms',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule],
  templateUrl: './dialog-agree-terms.component.html',
  styleUrl: './dialog-agree-terms.component.scss'
})
export class DialogAgreeTermsComponent {
  constructor(private dialogRef: MatDialogRef<DialogAgreeTermsComponent>) {
    // Do not allow closing by clicking outside.
    dialogRef.disableClose = true;
  }
}
