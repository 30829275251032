<a class="home-button" (click)="goHome()" title="Clear search and go to the home screen">
    <mat-icon>home</mat-icon>
</a>
<div class="search-form" (click)="focusInput()">
    <mat-chip-grid #chipGrid aria-label="search term input" (click)="focusInput()">
        <mat-chip-row *ngFor="let term of (terms$ | async)" (click)="focusInput()" [editable]="false" disableRipple (removed)="remove(term)">
            {{term}}
            <button matChipRemove [attr.aria-label]="'remove ' + term">
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-chip-row>
        <input placeholder='e.g. axoneme or LmxM.17.0070, or paste a list of gene IDs' #termInput [formControl]="termCtrl"
            [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
            [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
            (paste)="paste($event)" (focusin)="focus()"
            (matChipInputTokenEnd)="add($event)"/>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" class="mat-autocomplete">
            <mat-option *ngFor="let term of filteredTerms$ | async | slice:0:500" [value]="term">
                {{term}}
            </mat-option>
        </mat-autocomplete>
    </mat-chip-grid>
</div>
<a class="help-button" (click)="showHelpService.toggleHelp()" title="Show/hide help" *ngIf="breakpointService.smallScreen | async">
    <mat-icon>{{ (showHelpService.helpVisible | async) ? "cancel" : "help" }}</mat-icon>
</a>
