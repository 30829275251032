import { HeatmapGenotypingEntry, HeatmapTimepointEntry } from "./heatmap";
import { mean, median, pad } from "./statistics";
import { Vector2 } from "./vector";

export interface Serializable<T> {
    deserialize(input: Object): T;
}

export class Timepoint {
    timepoint: string;
    abundance: number[];
    referenceChange: number[];
    pValue: number;
    effectSize: number;
    isEmpty: boolean = true;

    style: HeatmapTimepointEntry;

    constructor() {
        this.style = new HeatmapTimepointEntry(this);
    }

    get meanAbundance(): number {
        if (this.isEmpty) return NaN;
        return mean(pad(this.abundance, 5, 0));
    }

    get medianAbundance(): number {
        if (this.isEmpty) return NaN;
        return median(pad(this.abundance, 5, 0));
    }

    get meanRelParental(): number {
        return this.meanAbundance / this.meanParentalAbundance;
    }

    get meanParentalAbundance(): number {
        if (this.isEmpty) return NaN;
        return mean(this.referenceChange);
    }

    get medianParentalAbundance(): number {
        if (this.isEmpty) return NaN;
        return median(this.referenceChange);
    }

    get medianRelParental(): number {
        return this.medianAbundance / this.medianParentalAbundance;
    }

    deserialize(input: any, referenceChanges: number[][]) {
        this.abundance = input.changes;
        this.referenceChange = referenceChanges[input.reference];
        this.pValue = input.pValue;
        this.effectSize = input.effectSize;
        this.isEmpty = false;

        this.style = new HeatmapTimepointEntry(this);
        
        return this;
    }
}

export interface TimepointMap {
    [timepoints: number] : Timepoint;
}

export class Assay {
    type: string;
    timepoints: TimepointMap;
    timepointList: Timepoint[];

    get isEmpty(): boolean {
        return this.timepointList.reduce((all, current) => (all && current.isEmpty), true);
    }

    constructor(assayType: string) {
        this.type = assayType;
        this.timepoints = {}
        if (!BarSeqData.AssayTimepoints.has(assayType)) {
            throw Error(`Unknown assay type ${assayType}`);
        }
        const tpList = BarSeqData.AssayTimepoints.get(assayType)?.sort((a, b) => (a-b))!
        for (const tp of tpList) {
            this.timepoints[tp] = new Timepoint();
        }
        this.timepointList = Object.values(this.timepoints);
    }

    deserialize(input: any, referenceChanges: number[][]) {
        for (const [tpstr, entry] of Object.entries(input)) {
            const tp = Number(tpstr);
            if (tp in this.timepoints) {
                this.timepoints[Number(tpstr)].deserialize(entry, referenceChanges);
            }
        }

        return this;
    }
}

export interface AssayMap {
    [assays: string] : Assay;
}

export class BarSeqData {
    assays: AssayMap;
    static AssayTimepoints: Map<string, number[]> = new Map([
        ['PRO', [24, 48, 72]],
        ['AXA', [24, 72]],
        ['IMAC', [24, 72]],
        ['FP', [72, 504, 1008]]
    ])
    
    constructor() {
        this.assays = {}
        for (const assayType of BarSeqData.AssayTimepoints.keys()) {
            this.assays[assayType] = new Assay(assayType);
        }
    }

    deserialize(input: any, referenceChanges: number[][]) {
        for (const [assaystr, entry] of Object.entries(input)) {
            this.assays[assaystr].deserialize(entry, referenceChanges);
        }

        return this;
    }

    get isEmpty(): boolean {
        for (const assay of Object.values(this.assays)) {
            if (!assay.isEmpty) {
                return false;
            }
        }
        return true;
    }
}

export class Genotyping {
    CellLineSurvived: boolean | undefined;
    ParentalORFDetected: boolean | undefined;
    ORFDeleted: boolean | undefined;
    DrugMarker: boolean | undefined;

    ORFDeletedStyle: HeatmapGenotypingEntry
    DrugMarkerStyle: HeatmapGenotypingEntry

    constructor(genotyping: any) {
        this.CellLineSurvived = genotyping.CellLineSurvived;
        this.ParentalORFDetected = genotyping.ParentalORFDetected;
        this.ORFDeleted = genotyping.ORFDeleted;
        this.DrugMarker = genotyping.DrugMarker;

        this.ORFDeletedStyle = new HeatmapGenotypingEntry(this.ORFDeleted);
        this.DrugMarkerStyle = new HeatmapGenotypingEntry(this.DrugMarker);
    }
}

export interface GeneMap {
    [genes: string] : Gene;
}

const controls: Map<string, string> = new Map([
    ['LmxM.02.0120', 'Datm'],
    ['LmxM.04.0680', 'Dubc2'],
    ['LmxM.20.1400', 'Dpf16'],
    ['LmxM.20_36.6470', 'Dmpk1'],
    ['LmxM.23.0490', 'Dampkb'],
    ['LmxM.28.1670', 'Dzfk'],
    ['LmxM.36.5850', 'DKH1']
]);

export class Gene {
    id: string;
    symbol: string | undefined;
    description: string;
    contig: string;
    leishtagAnnotations: LeishTagAnnotation;
    lopitAnnotations: LOPITAnnotation;
    genotyping: Genotyping | undefined = undefined;
    barseqData: BarSeqData;
    isControlMutant: boolean = false;
    tBruceiOrthologs: string[];
    orthologs: Orthologs;

    leishtagStyle: string;
    lopitStyle: string;

    deserialize(input: any, referenceChanges: number[][]) {
        this.id = input.geneId;
        this.symbol = input.symbol;
        this.description = input.description;
        this.contig = input.contig;
        this.leishtagAnnotations = new LeishTagAnnotation(input.leishtagAnnotations);
        this.lopitAnnotations = new LOPITAnnotation(input.lopitAnnotations); //new LocalisationAnnotationCollection(input.lopitAnnotations.map(LocalisationAnnotation.parse));
        this.barseqData = new BarSeqData().deserialize(input.barSeqData, referenceChanges);
        this.isControlMutant = controls.has(this.id);
        this.tBruceiOrthologs = input.tBruceiOrthologs;
        this.orthologs = input.orthologs;

        if (input.genotyping) {
            this.genotyping = new Genotyping(input.genotyping);
        }

        if (this.leishtagAnnotations.status == "annotated") {
            this.leishtagStyle = "yes";
        } else if ((this.leishtagAnnotations.status == "attempted") || (this.leishtagAnnotations.status == "scheduled")) {
            this.leishtagStyle = "unknown";
        } else {
            this.leishtagStyle = "no";
        }

        if ((this.lopitAnnotations.status == "detected") && (this.lopitAnnotations.entries.length > 0)) {
            this.lopitStyle = "yes";
        } else if (this.lopitAnnotations.status == "detected") {
            this.lopitStyle = "unknown";
        } else {
            this.lopitStyle = "no";
        }

        return this;
    }

    static deserializeGeneList(jsonGeneList: any[], referenceChanges: number[][]): GeneMap {
        const genes: GeneMap = {};
        for (const input of jsonGeneList) {
            const gene = new Gene().deserialize(input, referenceChanges);
            genes[gene.id] = gene;
        }
        return genes; 
    }
}

export class Contig {
    private _genes: Gene[]
    constructor (public name: string) {
        this._genes = [];
    }

    add(gene: Gene) {
        this._genes.push(gene);
    }

    get genes(): Gene[] {
        return this._genes;
    }
}

export interface LeishTagAnnotationTerm {
    term: string;
    modifiers: string[];
}

export interface LeishTagAnnotationStage {
    entries: LeishTagAnnotationTerm[];
    similar?: boolean;
    background: boolean;
}

export interface LeishTagAnnotationTerminus {
    PRO: LeishTagAnnotationStage | null;
    AM: LeishTagAnnotationStage | null;
}

export interface LeishTagAnnotationEntry {
    N: LeishTagAnnotationTerminus;
    C: LeishTagAnnotationTerminus;
    status: string;
}

export class LeishTagAnnotation {
    public N: LeishTagAnnotationTerminus;
    public C: LeishTagAnnotationTerminus;
    public status: string;

    hasAnnotationTerms(): boolean {
        for (const terminus of [this.N, this.C]) {
            if ((terminus.PRO) || (terminus.AM)) {
                return true;
            }
        }
        return false;
    }

    constructor (annotation: LeishTagAnnotationEntry) {
        this.N = annotation.N;
        this.C = annotation.C;
        this.status = this.hasAnnotationTerms() ? 'annotated' : annotation.status;
    }

    uniqueTermsNoModifiers(): string[] {
        const termlist = [this.N, this.C].reduce((prev: string[], terminus) => {
            if ((terminus.PRO) && (!terminus.PRO.background)) {
                prev.push(...terminus.PRO.entries.map((item) => item.term));
            }
            if ((terminus.AM) && (!terminus.AM.background)) {
                prev.push(...terminus.AM.entries.map((item) => item.term));
            }
            return prev;
        }, []);
        return [...new Set(termlist)].sort();
    }

    uniquePRONoModifiers(): string[] {
        const termlist = [this.N, this.C].reduce((prev: string[], terminus) => {
            if ((terminus.PRO) && (!terminus.PRO.background)) {
                prev.push(...terminus.PRO.entries.map((item) => item.term));
            }
            return prev;
        }, []);
        return [...new Set(termlist)].sort();
    }
}

export class LOPITAnnotation {
    status: string;
    entries: string[];
    tsne: Vector2;

    constructor(annotation: any) {
        this.status = annotation.status;
        if (this.status == "detected") {
            this.entries = annotation.entries;
            this.tsne = new Vector2(annotation.tsne.x, annotation.tsne.y);
        } else {
            this.entries = [];
            this.tsne = new Vector2(NaN, NaN);
        }
    }
}

export type Orthologs = {
    [organism: string]: string[];
}