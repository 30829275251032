import { Component, OnDestroy, OnInit } from '@angular/core';
import { SelectedService } from '../selected.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-up-down-controls',
  templateUrl: './up-down-controls.component.html',
  styleUrls: ['./up-down-controls.component.scss']
})
export class UpDownControlsComponent implements OnInit, OnDestroy {
  private readonly destroyed$ = new Subject<void>();

  visible: boolean = false;
  constructor(
    private selectedService: SelectedService,
    ) {}

  ngOnInit(): void {
    this.selectedService.selected
      .pipe(takeUntil(this.destroyed$))
      .subscribe(gene => this.visible = (gene) ? true : false);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  upClick(): void {
    const currentGene = this.selectedService.selected.getValue()
    if (currentGene !== undefined) {
      this.selectedService.selectByIndex(currentGene.index - 1);
    }
  }
  downClick(): void {
    const currentGene = this.selectedService.selected.getValue()
    if (currentGene !== undefined) {
      this.selectedService.selectByIndex(currentGene.index + 1);
    }
  }
}
