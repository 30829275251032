import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Contig } from '../gene';
import { Subject, ReplaySubject, combineLatest, map, takeUntil } from 'rxjs';

import { SearchService } from '../search.service';
import { GeneEntry, SelectedService } from '../selected.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
  selector: 'app-gene-list',
  templateUrl: './gene-list.component.html',
  styleUrls: ['./gene-list.component.scss'],
})
export class GeneListComponent implements OnInit, OnDestroy {
  private readonly destroyed$ = new Subject<void>();
  public filteredGeneList$: ReplaySubject<GeneListEntry[]> = new ReplaySubject<GeneListEntry[]>(1);
  public isSingleGene$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  private _geneListEntryLocations: ReplaySubject<GeneListEntryLocations> = new ReplaySubject<GeneListEntryLocations>(1);
  public constructor(
    private searchService: SearchService,
    private selectedService: SelectedService,
  ) {
  }

  @ViewChild(CdkVirtualScrollViewport) scrollViewPort: CdkVirtualScrollViewport;

  public ngOnInit(): void {
    combineLatest(
      [this.searchService.sortedSearchResults, this.selectedService.selected])
      .pipe(map(([list, selected]) => {
        return (list.length == 1) && (!selected);
      }))
      .pipe(takeUntil(this.destroyed$))
      .subscribe(this.isSingleGene$);

    this.selectedService.indexedGeneList
      .pipe(map(insertContigOnChange))
      .pipe(takeUntil(this.destroyed$))
      .subscribe(this.filteredGeneList$);
    this.filteredGeneList$
      .pipe(map((list) => {
        const entries: GeneListEntryLocations = {};
        list.filter((entry) => !entry.isContig).forEach((entry) => entries[entry.geneEntry!.index] = entry.pxlLocation)
        return entries;
      }))
      .pipe(takeUntil(this.destroyed$))
      .subscribe(this._geneListEntryLocations);
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  geneOnClick(event: Event, gene: GeneEntry) {
    this.selectedService.selectByIndex(gene.index);
    // this.upDownControlsService.selectedIndex.next(gene.index);
    event.stopPropagation();
  }

  viewportOnClick(event: Event) {
    this.selectedService.selectById("");
  }
}

function insertContigOnChange(geneList: GeneEntry[]): (GeneListEntry)[] {
  const retList: GeneListEntry[] = [];
  var lastContig: string = "";
  var pxlLocation: number = 0;
  for (const gene of geneList) {
    if (gene.gene.contig != lastContig) {
      retList.push({
        pxlLocation: pxlLocation,
        isContig: true,
        geneEntry: undefined,
        contig: new Contig(gene.gene.contig),
      });
      pxlLocation += 19;
      lastContig = gene.gene.contig;
    }
    retList.push({
      pxlLocation: pxlLocation,
      isContig: false,
      geneEntry: gene,
      contig: undefined,
    });
    pxlLocation += 3;
  }
  return retList;
}

interface SelectedGeneIndex {
  index: number;
}

interface GeneListEntry {
  pxlLocation: number;
  isContig: boolean;
  geneEntry: GeneEntry | undefined;
  contig: Contig | undefined;
}

interface GeneListEntryLocations {
  [index: number]: number;
}