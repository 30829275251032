import { Injectable, OnDestroy } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ReplaySubject, Subject, map, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreakpointService implements OnDestroy {
  private readonly destroyed$ = new Subject<void>();
  public smallScreen = new ReplaySubject<boolean>(1);
  constructor(
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver
      //.observe([Breakpoints.XSmall])
      .observe(["(max-width: 812px)"])
      .pipe(
        map((results) => results.matches),
        takeUntil(this.destroyed$)
      )
      .subscribe(this.smallScreen);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}
