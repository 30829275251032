import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { GeneListComponent } from './gene-list/gene-list.component';
import { UpDownControlsComponent } from './up-down-controls/up-down-controls.component';
import { LegendsComponent } from './legends/legends.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { RouterOutlet, provideRouter, withComponentInputBinding } from '@angular/router';
import { routes } from './app.routes';
import { GeneDetailviewComponent } from './gene-detailview/gene-detailview.component';
import { PhenotypingGraphsComponent } from './phenotyping-graphs/phenotyping-graphs.component';
import { LocalisationDiagramComponent } from './localisation-diagram/localisation-diagram.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LandingComponent } from './landing/landing.component';
import { LopitDiagramComponent } from './lopit-diagram/lopit-diagram.component';
import { RecreateViewOnChangeDirective } from './recreate-view-on-change.directive';

@NgModule({
  declarations: [
    AppComponent,
    GeneListComponent,
    GeneDetailviewComponent,
    UpDownControlsComponent,
    LandingComponent,
    LegendsComponent,
    LoadingSpinnerComponent,
    GeneDetailviewComponent,
    PhenotypingGraphsComponent,
    LocalisationDiagramComponent,
    LopitDiagramComponent,
    RecreateViewOnChangeDirective,
  ],
  imports: [
    ScrollingModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatListModule,
    SearchBarComponent,
    RouterOutlet,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [provideRouter(routes, withComponentInputBinding())],
  bootstrap: [AppComponent]
})
export class AppModule { }
