<div class="legends">
    <!-- <svg class="fitness" viewBox="-0.2 -0.2 1.3 1.3">
        <defs>
            <linearGradient id="magentaturquoise">
                <stop offset="0%" class="stop1"/>
                <stop offset="100%" class="stop2"/>
            </linearGradient>
        </defs>
        <style>
            .stop1 {
                stop-color: var(--color-no);
            }
            .stop2 {
                stop-color: var(--color-yes);
            }
        </style>
        <rect x="0" y="0" width="1" height="1" fill="url(#magentaturquoise)"/>
        <text x="0" y="-0.05" class="xlabel" text-anchor="start" dominant-baseline="auto">no</text>
        <text x="1" y="-0.05" class="xlabel" text-anchor="end" dominant-baseline="auto">yes</text>
    </svg> -->
    <svg class="fitness" viewBox="-0.2 -0.2 1.3 1.3">
        <defs>
            <linearGradient id="redblue">
                <stop offset="0%" stop-color="red"/>
                <stop offset="50%" stop-color="white"/>
                <stop offset="100%" stop-color="blue"/>
            </linearGradient>
            <linearGradient id="greytransparent" x1="0" x2="0" y1="0" y2="1">
                <stop offset="0%" stop-color="transparent"/>
                <stop offset="86%" stop-color="#bbb"/>
            </linearGradient>
        </defs>
        <rect x="0" y="0" width="1" height="1" fill="url(#redblue)"/>
        <rect x="0" y="0"  width="1" height="1" fill="url(#greytransparent)"/>
        <path d="M 0 0.8 H 1" stroke="black" stroke-width="0.02"/>
        <text x="0.5" y="-0.05" class="xlabel" text-anchor="middle" dominant-baseline="auto">- Fitness +</text>
        <text x="-0.1" y="0.5" class="ylabel" text-anchor="middle" dominant-baseline="auto">-log(p)</text>
    </svg>
</div>