import { Title, Meta } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
import { SelectedService } from './selected.service';
import { Gene } from './gene';

@Injectable({
  providedIn: 'root'
})
export class PageDetailService {

  constructor(
    private selectedService: SelectedService,
    private titleService: Title,
    private metaService: Meta,
  ) {
    this.selectedService.selected.subscribe((entry) => {
      if (entry) {
        this.titleService.setTitle(entry.gene.id + ' - LeishGEM data browser');
        this.metaService.updateTag({ name: 'description', content: entry.gene.description });
        this.updateJsonLD(entry.gene);
      } else {
        this.titleService.setTitle('LeishGEM data browser');
        this.metaService.updateTag({ name: 'description', content: "Explore the LeishGEM project's results as they come in."});
        this.updateJsonLD(undefined);
      }
    });
  }
  
  updateJsonLD(gene: Gene | undefined) {
    const head = document.getElementsByTagName('head')[0];
    if (gene === undefined) {
      head.querySelector('#gene-scheme')?.remove();
    } else {
      var geneSchemeElement: HTMLScriptElement | null = head.querySelector('#gene-scheme');
      if (geneSchemeElement === null) {
        geneSchemeElement = document.createElement('script');
        geneSchemeElement.setAttribute('type', 'application/ld+json');
        geneSchemeElement.setAttribute('id', 'gene-scheme');
        head.appendChild(geneSchemeElement);
      }
      geneSchemeElement!.text = JSON.stringify({
        "@context": "http://schema.org/",
        "@type": "Gene",
        "name": gene.id,
        "description": gene.description,
        "taxonomicRange": {
          "@context": "http://schema.org/",
          "@type": "Taxon",
          "taxonRank": "https://www.wikidata.org/wiki/Q2493771",
          "name": "Leishmania mexicana"
        }
      });
    }
  }
}
