import { Directive, EmbeddedViewRef, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[recreateViewOnChange]',
})
export class RecreateViewOnChangeDirective implements OnChanges {
  @Input('recreateViewOnChange') key: any;

  viewReference: EmbeddedViewRef<any> | null;

  constructor(
    private templateReference: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['key']) {
      this.destroyView();
      setTimeout(() => this.createView(), 350);
    }
  }

  private createView() {
    this.viewReference = this.viewContainer.createEmbeddedView(this.templateReference);
  }

  private destroyView() {
    if (this.viewReference) {
      this.viewReference.destroy();
      this.viewReference = null;
    }
  }
}
