<mat-card class="gene-list-card">
    <mat-card-header>
        <mat-card-title>Gene list</mat-card-title>
    </mat-card-header>
    <ng-container *ngIf="filteredGeneList$ | async as filteredGeneList">
        <ng-container *ngIf="filteredGeneList.length > 0 else noresults">
        <div class="list-container">
        <mat-toolbar class="header" color="primary">
            <div class="header-block">
                <mat-icon color="accent" svgIcon="promastigote"></mat-icon>
                <div class="header-timepoints">
                    <div class="header-timepoint">1d</div>
                    <div class="header-timepoint">2d</div>
                    <div class="header-timepoint">3d</div>
                </div>
            </div>
            <div class="header-block">
                <mat-icon color="accent" svgIcon="amastigote"></mat-icon>
                <div class="header-timepoints">
                    <div class="header-timepoint">1d</div>
                    <div class="header-timepoint">3d</div>
                </div>
            </div>
            <div class="header-block">
                <mat-icon color="accent" svgIcon="macrophage"></mat-icon>
                <div class="header-timepoints">
                    <div class="header-timepoint">1d</div>
                    <div class="header-timepoint">3d</div>
                </div>
            </div>
            <div class="header-block">
                <mat-icon color="accent" svgIcon="mouse"></mat-icon>
                <div class="header-timepoints">
                    <div class="header-timepoint">3d</div>
                    <div class="header-timepoint">3w</div>
                    <div class="header-timepoint">6w</div>
                </div>
            </div>
            <div class="header-block">
                <mat-icon color="accent" svgIcon="dna"></mat-icon>
                <div class="header-timepoints">
                    <div class="header-timepoint">ORF</div>
                    <div class="header-timepoint">DNA</div>
                </div>
            </div>
            <div class="header-block">
                <mat-icon color="accent" svgIcon="microscope"></mat-icon>
                <div class="header-timepoints">
                    <div class="header-timepoint">Tag</div>
                    <div class="header-timepoint">MS</div>
                </div>
            </div>
        </mat-toolbar>
        <div class="heatmap-list-container">
                <cdk-virtual-scroll-viewport
                    itemSize="10"
                    class="heatmap-list" (click)="viewportOnClick($event)">
                    <ng-container *cdkVirtualFor="let entry of filteredGeneList">
                        <ng-container *ngIf="entry.isContig then contigBlock else geneBlock"></ng-container>
                        <ng-template #geneBlock>
                            <ng-container *ngIf="entry.geneEntry.gene as gene">
                                <div class="heatmap-entry"
                                    [title]="gene.id"
                                    (click)="geneOnClick($event, entry.geneEntry)"
                                    [class.heatmap-entry-selected]="entry.geneEntry.selected">
                                    <div class="heatmap-entry-assay" *ngFor="let assay of ['PRO', 'AXA', 'IMAC', 'FP']">
                                        <div class="heatmap-entry-timepoint" *ngFor="let timepoint of gene.barseqData.assays[assay].timepointList">
                                            <div style="height: 100%; background-color: {{timepoint.style.color}};"></div>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="gene.genotyping then genotyping else nogenotyping"></ng-container>
                                    <ng-template #genotyping>
                                        <ng-container *ngIf="gene.genotyping.CellLineSurvived then survived else notsurvived"></ng-container>
                                        <ng-template #survived>
                                            <div class="heatmap-genotyping heatmap-block">
                                                <div class="heatmap-yesnoblock" style="background-color: {{gene.genotyping.ORFDeletedStyle.color}};"></div>
                                                <div class="heatmap-yesnoblock" style="background-color: {{gene.genotyping.DrugMarkerStyle.color}};"></div>
                                            </div>
                                        </ng-template>
                                        <ng-template #notsurvived>
                                            <div class="heatmap-genotyping heatmap-block">
                                                <div class="heatmap-yesnoblock" style="background-color: var(--color-maybe);"></div>
                                                <div class="heatmap-yesnoblock" style="background-color: var(--color-maybe);"></div>
                                            </div>
                                        </ng-template>
                                    </ng-template>
                                    <ng-template #nogenotyping>
                                        <div class="heatmap-genotyping heatmap-block">
                                            <div class="heatmap-yesnoblock" style="background-color: var(--color-unknown);"></div>
                                            <div class="heatmap-yesnoblock" style="background-color: var(--color-unknown);"></div>
                                        </div>
                                    </ng-template>
                                    <div class="heatmap-annotations heatmap-block">
                                        <div class="heatmap-yesnoblock" style="background-color: var(--color-{{gene.leishtagStyle}});"></div>
                                        <div class="heatmap-yesnoblock" style="background-color: var(--color-{{gene.lopitStyle}});"></div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-template>
                        <ng-template #contigBlock>
                            <div class="heatmap-contig-entry">
                                <div class="heatmap-contig-entry-name">{{entry.contig!.name}}</div>
                            </div>
                        </ng-template>
                    </ng-container>
                </cdk-virtual-scroll-viewport>
        </div>
    </div>
    </ng-container>
    <ng-template #noresults>
        <div class="noresults">
            <div class="noresultsimage"></div>
            <div class="noresultstext">No results.</div>
        </div>
    </ng-template>
    </ng-container>
    <!-- Spinner component is active only when data is loaded -->
    <app-loading-spinner/>
</mat-card>
