import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShowHelpService {
  public helpVisible = new BehaviorSubject<boolean>(false);

  toggleHelp() {
    this.helpVisible.next(!this.helpVisible.value);
  }

  constructor() { }
}
