import { Component, Input } from '@angular/core';
import { LeishTagAnnotation } from '../gene';

@Component({
  selector: 'app-localisation-diagram',
  templateUrl: './localisation-diagram.component.html',
  styleUrls: ['./localisation-diagram.component.scss']
})
export class LocalisationDiagramComponent {
  public ids: string[] = [];

  @Input()
  set localisationTerms(annotations: LeishTagAnnotation) {
    this.ids = annotations.uniquePRONoModifiers().map((annotation) => translate(annotation.replaceAll(' ', '-')));
  }  
}

const _localisationMap: Map<string, string> = new Map([
  ["matrix", "mitochondrion"],
])
function translate(term: string): string {
  if (_localisationMap.has(term)) {
    return _localisationMap.get(term)!;
  }
  return term;
}
