import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject, combineLatest, distinctUntilChanged, map, takeUntil } from 'rxjs';
import { Gene } from './gene';
import { SearchService } from './search.service';

@Injectable({
  providedIn: 'root'
})
export class SelectedService implements OnDestroy {
  private readonly destroyed$ = new Subject<void>();

  private _selected$ = new BehaviorSubject<GeneEntry|undefined>(undefined);
  private _selectedIdOrIndex$ = new BehaviorSubject<string|number|undefined>(undefined);
  private _searchResults$ = new ReplaySubject<IndexedGeneList>(1);
  constructor(
    private searchService: SearchService,
  ) {
    this.searchService.sortedSearchResults
      .pipe(
        map((list) => new IndexedGeneList(list)),
        takeUntil(this.destroyed$)
      )
      .subscribe(this._searchResults$);
    
    combineLatest(
      {
        geneList: this._searchResults$,
        selectedIdOrIndex: this._selectedIdOrIndex$.pipe(distinctUntilChanged())
      }
      ).pipe(
        takeUntil(this.destroyed$),
        map((e) => {
          var selected = undefined;
          if (e.selectedIdOrIndex !== undefined) {
            const idOrIndex = e.selectedIdOrIndex;
            if (typeof e.selectedIdOrIndex === "string") { // It's a Gene ID
              selected = e.geneList.lookupGeneId(e.selectedIdOrIndex);
            } else { // It's an index
              selected = e.geneList.lookupGeneIndex(e.selectedIdOrIndex);
            }
          }
          if (e.geneList.length == 1) {
            if (e.selectedIdOrIndex == "unselect") {
              this.searchService.clearSearchTerms.next();
            } else if (selected === undefined) {
              selected = e.geneList.lookupGeneIndex(0);
            }
          }
          e.geneList.selectGeneId(selected?.gene.id);
          return selected;
        }),
      )
      .subscribe(this._selected$);

    this._selected$.subscribe((entry) => {
      if (entry) {
        entry.selected = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  selectById(geneId: string) {
    this._selectedIdOrIndex$.next(geneId);
  }

  selectByIndex(index: number) {
    this._selectedIdOrIndex$.next(index);
  }

  get selected(): BehaviorSubject<GeneEntry|undefined> {
    return this._selected$;
  }

  get indexedGeneList(): Observable<GeneEntry[]> {
    return this._searchResults$.pipe(map((list) => list.asList()));
  }
}

export interface GeneEntry {
  index: number,
  gene: Gene,
  selected: boolean,
}
interface GeneIDIndex {
  [id: string]: GeneEntry
}

class IndexedGeneList {
  private indexedList: GeneEntry[];
  private idIndex: GeneIDIndex = {};

  constructor(
    geneList: Gene[]
  ) {
    this.indexedList = geneList.map((gene, index) => {return {index: index, gene: gene, selected: false}})
    this.indexedList.forEach((entry) => this.idIndex[entry.gene.id] = entry);
  }

  get length(): number {
    return this.indexedList.length;
  }

  lookupGeneId(id: string): GeneEntry {
    return this.idIndex[id];
  }

  selectGeneId(id: string | undefined): GeneEntry | undefined {
    this.indexedList.forEach((entry) => entry.selected = false);
    if (id !== undefined) {
      const selected = this.lookupGeneId(id);
      if (selected) selected.selected = true;
      return selected;
    }
    return undefined;
  }

  lookupGeneIndex(index: number): GeneEntry {
    if (index < 0) {
      index = 0;
    } else if (index > this.indexedList.length - 1) {
      index = this.indexedList.length - 1;
    }
    return this.indexedList[index];
  }

  asList(): GeneEntry[] {
    return this.indexedList.slice();
  }
}