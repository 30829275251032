import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { UpDownControlsService } from './up-down-controls.service';
import { BreakpointService } from './breakpoint.service';
import { SelectedService } from './selected.service';
import { ReplaySubject, Subject, map, takeUntil } from 'rxjs';
import { ShowHelpService } from './show-help-service.service';
import { DialogAgreeTermsComponent } from './dialog-agree-terms/dialog-agree-terms.component';
import { MatDialog } from '@angular/material/dialog';
import { PageDetailService } from './page-detail.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'leishgemweb';

  private readonly destroyed$ = new Subject<void>();

  public selected$ = new ReplaySubject<boolean>(1);

  public helpVisible = false;

  constructor (
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private upDownControlsService: UpDownControlsService,
    public breakpointService: BreakpointService,
    public selectedService: SelectedService,
    private showHelpService: ShowHelpService,
    private agreeTermsDialog: MatDialog,
    private pageDetailService: PageDetailService,
  ) {
    this.matIconRegistry.addSvgIcon('promastigote', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/promastigote.svg'));
    this.matIconRegistry.addSvgIcon('amastigote', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/amastigote.svg'));
    this.matIconRegistry.addSvgIcon('macrophage', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/macrophage.svg'));
    this.matIconRegistry.addSvgIcon('mouse', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/mouse.svg'));
    this.matIconRegistry.addSvgIcon('dna', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/dna.svg'));
    this.matIconRegistry.addSvgIcon('microscope', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/microscope.svg'));
    this.matIconRegistry.addSvgIcon('delta', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/delta.svg'));
  }

  ngOnInit(): void {
    this.selectedService
      .selected.pipe(
        map((entry) => entry !== undefined),
        takeUntil(this.destroyed$)
      )
      .subscribe(this.selected$);
    this.showHelpService
      .helpVisible
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => { this.helpVisible = value});

    // Check if the user has agreed to our website terms of use
    if (!cookieExists('termsAgreed')) {
      // If not yet, show a modal dialog with the terms
      const dialogRef = this.agreeTermsDialog.open(DialogAgreeTermsComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // User has agreed, let's not bother them for a month
          document.cookie = "termsAgreed=yes; max-age=2678400; secure; samesite=strict";
        }
      })
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  @HostListener("document:keydown.ARROWUP", ['$event'])
  keyUp(event: KeyboardEvent): void {
    this.upDownControlsService.up();
    event.preventDefault();
  }
  @HostListener("document:keydown.ARROWDOWN", ['$event'])
  keyDown(event: KeyboardEvent): void {
    this.upDownControlsService.down();
    event.preventDefault();
  }

  toggleHelp() {
    this.helpVisible = !this.helpVisible;
  }

}

// Helper function to check if a cookie with the given name exists
function cookieExists(name: string): boolean {
  const matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  if (matches === null) {
    return false
  }
  return true;
}