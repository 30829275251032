import { Timepoint } from "./gene";

export function calculateGrayIntensity(pValue: number) {
    let value = pValue < 0.05 ? 1-Math.log10(pValue/0.05)/Math.log10(0.005/0.05) : 1;
    if (value < 0) value = 0;
    return value;
}

export class HeatmapTimepointEntry {
    public color: string = "var(--color-unknown)";

    constructor(timepoint: Timepoint) {
        if (!timepoint.isEmpty) {
            const grayIntensity = calculateGrayIntensity(timepoint.pValue);
            let colorIntensity = Math.abs(Math.log2(timepoint.effectSize))/2;
            if (colorIntensity > 1) colorIntensity = 1;

            // Figure out color
            var color;
            if (timepoint.effectSize > 1) {
                color = [0, 0, 1];
            } else {
                color = [1, 0, 0];
            }

            // Blend with white
            color = color.map((value) => 1-(1-value)*colorIntensity);

            // Blend with gray
            color = color.map((value) => 255*(value*(1-grayIntensity) + 0.7333*grayIntensity));

            this.color = "rgb(" + color.map(String).join(',') + ")";
        }
    }
}

export class HeatmapAnnotationEntry {
    private _color: string;

    constructor(annotations: any | undefined) {
        if (annotations === undefined) {
            this._color = "var(--color-unknown";
        } else if (annotations.length > 0) {
            this._color = "var(--color-yes)";
        } else {
            this._color = "var(--color-unknown)";
        }
    }

    get color() {
        return this._color;
    }
}

export class HeatmapGenotypingEntry {
    private _color: string;

    constructor(decision: boolean | undefined) {
        if (decision === undefined) {
            this._color = "var(--color-unknown";
        } else if (decision) {
            this._color = "var(--color-yes)";
        } else {
            this._color = "var(--color-no)";
        }
    }

    get color() {
        return this._color;
    }
}