<div class="app-container">
    <!-- Search bar on top -->
    <app-search-bar class="app-row app-header" (toggleHelp)="toggleHelp()"/>
    <!-- Everything else below the search bar -->
    <div class="content">
        <!-- Bind breakpoint smallScreen to variable to be responsive to screen size changes -->
        <ng-container *ngIf="[(breakpointService.smallScreen | async)] as smallScreen">
            <!-- Similarly, bind to the selected subject to react to changes in selection -->
            <ng-container *ngIf="[(selected$ | async)] as selected">
                <!-- Gene list component
                    On a small screen, the gene list is visible only if nothing is selected and the help screen isn't active.
                    On a large screen, the gene list is always visible.
                -->
                <app-gene-list [class.hidden]="smallScreen[0] && (selected[0] || helpVisible)"/>
                <!-- Help screen (landing)
                    On a small screen, the help screen is only visible when actively requested via the "?" button.
                    On a large screen, the help screen is the landing page (i.e. visible if no gene is selected).
                -->
                <app-landing [class.hidden]="((smallScreen[0] && (!helpVisible)) || ((!smallScreen[0]) && selected[0]))"/>
                <!-- Detail view component
                    The detail component is visible when a gene is selected and hidden otherwise, regardless of screen size.
                 -->
                <app-gene-detailview [class.hidden]="(!selected[0]) || (smallScreen[0] && helpVisible)" [gene]="(selectedService.selected | async)?.gene"/>
            </ng-container>
        </ng-container>
    </div>
    <!-- Up/down button component (navigates the gene list, only visible in detail view) -->
    <app-up-down-controls/>
</div>