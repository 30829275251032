import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject, combineLatest, map, takeUntil } from 'rxjs';
import { Gene } from './gene';
import { SearchIndex } from './searchindex';
import { DataLoaderService } from './data-loader.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService implements OnDestroy {
  private readonly destroyed$ = new Subject<void>();

  private _searchTerms$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private _searchResults$: ReplaySubject<Gene[]> = new ReplaySubject<Gene[]>(1);
  private _sortedSearchResults$: ReplaySubject<Gene[]> = new ReplaySubject<Gene[]>(1);
//  private _filteredGenesSubject$: BehaviorSubject<Observable<Gene[]>>;
  private searchIndex$: ReplaySubject<SearchIndex> = new ReplaySubject<SearchIndex>(1);

  public clearSearchTerms = new Subject<void>;

  constructor(
    private dataLoaderService: DataLoaderService,
  ) {
    this.dataLoaderService.searchIndex
      .pipe(takeUntil(this.destroyed$))
      .subscribe(this.searchIndex$);

    combineLatest({
      searchIndex: this.searchIndex$,
      searchTerms: this._searchTerms$,
    }).pipe(
      map((input) => input.searchIndex.search(input.searchTerms)),
      takeUntil(this.destroyed$)
    ).subscribe(this._searchResults$);

    this._searchResults$
     .pipe(
        map((list: Gene[]) => list.sort((a, b) => cmpLmxM(a, b) || cmpContig(a, b) || cmpGeneId(a, b))),
        takeUntil(this.destroyed$)
      ).subscribe(this._sortedSearchResults$);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  get searchResults(): Observable<Gene[]> {
    return this._searchResults$.asObservable();
  }

  get sortedSearchResults(): Observable<Gene[]> {
    return this._sortedSearchResults$.asObservable();
  }

  get searchTermsSubject(): BehaviorSubject<string[]> {
    return this._searchTerms$;
  }

  get indexTerms(): Observable<string[]> {
    return this.searchIndex$.pipe(map((searchIndex) => searchIndex.searchTerms));
  }

  get singleGene(): Observable<Gene|null> {
    return this.searchResults.pipe(map((list) => {
      if (list.length != 1) {
        return null;
      }
      return list[0];
    }));
  }
}

function cmpLmxM(a: Gene, b: Gene): number {
  let astart = a.contig.startsWith('LmxM.') ? 0 : 1;
  let bstart = b.contig.startsWith('LmxM.') ? 0 : 1;
  return astart - bstart
}

function cmpContig(a: Gene, b: Gene): number {
  return a.contig.localeCompare(b.contig);
}

function cmpGeneId(a: Gene, b: Gene): number {
  return a.id.localeCompare(b.id);
}
