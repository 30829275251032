import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UpDownControlsService {
  public selectedIndex: BehaviorSubject<number> = new BehaviorSubject<number>(-1);

  up(): void {
    this.selectedIndex.next(this.selectedIndex.value - 1);
  }

  down(): void {
    this.selectedIndex.next(this.selectedIndex.value + 1);
  }
}
