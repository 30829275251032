import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AsyncSubject, BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Gene, GeneMap } from './gene';
import { SearchIndex } from './searchindex';

@Injectable({
  providedIn: 'root'
})
export class DataLoaderService implements OnDestroy {
  genesUrl = './assets/genes.json';
  searchIndexUrl = './assets/searchindex.json';

  private readonly destroyed$ = new Subject<void>();

  private _loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private _genes: AsyncSubject<GeneMap> = new AsyncSubject<GeneMap>();
  private _searchIndex: AsyncSubject<SearchIndex> = new AsyncSubject<SearchIndex>();

  constructor(
    private http: HttpClient
  ) {
    this.requestGeneData();
    this._searchIndex
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this._loading$.next(false));
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private deserialiseGenes(response: any): GeneMap {
    return Gene.deserializeGeneList(response['geneList'], response['referenceChanges']);
  }
  private constructSearchIndex(genes: GeneMap): SearchIndex {
    const index = new SearchIndex(genes);
    return index;
  }

  private requestGeneData() {
    this._genes
      .pipe(
        map(this.constructSearchIndex),
        takeUntil(this.destroyed$)
      )
      .subscribe(this._searchIndex);
    this.http.get(this.genesUrl)
      .pipe(
        map(this.deserialiseGenes),
        takeUntil(this.destroyed$)
      )
      .subscribe(this._genes);
  }

  get genes(): Observable<GeneMap> {
    return this._genes.asObservable();
  }

  get searchIndex(): Observable<SearchIndex> {
    return this._searchIndex.asObservable();
  }

  get loading$(): Observable<boolean> {
    return this._loading$.asObservable();
  }
}

interface GeneData {
  genes: GeneMap;
  searchIndex: SearchIndex; 
}