import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ReplaySubject, Subject, map, takeUntil } from 'rxjs';
import { DataLoaderService } from '../data-loader.service';
import { Gene, GeneMap } from '../gene';
import { Vector2, Vector2Collection, Vector2Transformation } from '../vector';

@Component({
  selector: 'app-lopit-diagram',
  standalone: false,
  templateUrl: './lopit-diagram.component.html',
  styleUrl: './lopit-diagram.component.scss'
})
export class LopitDiagramComponent implements OnInit, OnDestroy {
  private readonly destroyed$ = new Subject<void>();
  public TSNELocations = new ReplaySubject<Map<string, LOPITInfo>>(1);

  @Input() gene: Gene;

  @ViewChild('lopitsvg', {static: false}) lopitSvg: ElementRef;

  constructor(
    private dataLoaderService: DataLoaderService,
  ) {}

  ngOnInit(): void {
    this.dataLoaderService.genes
      .pipe(takeUntil(this.destroyed$))
      .pipe(map((genes: GeneMap) => {
        const locations: [string, LOPITInfo][] = (Object.entries(genes)
          .filter(([name, gene]) => gene.lopitAnnotations.status == "detected")
          .map(([name, gene]) => [name, {terms: gene.lopitAnnotations.entries, location: gene.lopitAnnotations.tsne}])
        );
        const loclist = new Vector2Collection(locations.map(([name, entry]) => entry.location));
        const min = loclist.min();
        const max = loclist.max();
        const extent = max.subtractVector(min);
        const edge = extent.multiplyNumber(0.025);
        const datamin = min.subtractVector(edge);
        const datamax = max.addVector(edge);

        const view = new Vector2(500, 500);
        const dataextent = datamax.subtractVector(datamin);
        const transformation = new Vector2Transformation(datamin, view.divideElementWise(dataextent));

        return new Map(locations.map(([name, entry]) => {
          return [name, {terms: entry.terms, location: transformation.transform(entry.location)}];
        }));
      }))
      .subscribe(this.TSNELocations);
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  whichMarker(info: LOPITInfo, geneInfo: LOPITInfo): string {
    var intersections = 0;
    for (const term of info.terms) {
      if (geneInfo.terms.indexOf(term) >= 0) {
        intersections++;
      }
    }
    return `#gene-marker-highlight-${intersections}`;
  }

  downloadLopitSvg() {
    const serialiser = new XMLSerializer();
    const code = serialiser.serializeToString(this.lopitSvg.nativeElement)
    navigator.clipboard.writeText(code);
  }
}

interface LOPITInfo {
  terms: string[];
  location: Vector2;
}
