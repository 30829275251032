import { ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteModule, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipGrid, MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, map, skipUntil, takeUntil } from 'rxjs/operators';
import { MatIconModule } from '@angular/material/icon';
import { NgIf, NgFor, AsyncPipe, SlicePipe } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { SearchService } from '../search.service';
import { ActivatedRoute, ActivationEnd, NavigationStart, Router } from '@angular/router';
import { SelectedService } from '../selected.service';
import { BreakpointService } from '../breakpoint.service';
import { ShowHelpService } from '../show-help-service.service';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  standalone: true,
  imports: [
    ScrollingModule,
    FormsModule,
    // MatAutoCompleteModule needs to come before MatFormFieldModule to ensure that the add event occurs before the selected event.
    // This is an Angular bug (https://github.com/angular/components/issues/13574)
    MatAutocompleteModule,
    MatFormFieldModule,
    MatChipsModule,
    NgIf,
    NgFor,
    MatIconModule,
    ReactiveFormsModule,
    AsyncPipe,
    SlicePipe,
  ]
})

export class SearchBarComponent implements OnInit, OnDestroy {
  private readonly destroyed$ = new Subject<void>();
  private readonly setupComplete$ = new Subject<void>();

  separatorKeyCodes: number[] = [ENTER];
  termCtrl = new FormControl('');
  filteredTerms$: Observable<string[]>;
  terms$ = new BehaviorSubject<string[]>([]);
  allTerms : string[] = [];

  @ViewChild('termInput') termInput: ElementRef<HTMLInputElement>;
  @ViewChild('chipGrid') chipGrid: ElementRef<MatChipGrid>;
  @ViewChild('auto') chipAutoComplete: MatAutocomplete;

  announcer = inject(LiveAnnouncer);

  constructor(
    private activatedRoute: ActivatedRoute,
    private searchService: SearchService,
    private selectedService: SelectedService,
    public breakpointService: BreakpointService,
    public showHelpService: ShowHelpService,
    private router: Router,
    ) {}

  ngOnInit(): void {
    // Allow clearing of search terms from the search service
    this.searchService.clearSearchTerms
      .pipe(
        takeUntil(this.destroyed$),
        map(() => [])
      )
      .subscribe(this.terms$);

    this.terms$
      .pipe(
        takeUntil(this.destroyed$),
        distinctUntilChanged(termArrayEquality),
      )
      .subscribe(this.searchService.searchTermsSubject);

    this.terms$
      .pipe(
        takeUntil(this.destroyed$),
        distinctUntilChanged(termArrayEquality),
      )
      .subscribe((terms) => {
        this.showHelpService.helpVisible.next(false);
        this.selectedService.selectById("");
      })

    this.filteredTerms$ = combineLatest({
      inputValue: this.termCtrl.valueChanges,
      allTerms: this.searchService.indexTerms,
    }).pipe(map((input) => {
      if (input.inputValue) {
        return this._filter(input.allTerms, input.inputValue);
      }
      return [];
    }));

    var processNextActivation: boolean = true;
    this.router.events.pipe(
      filter((event) => (event instanceof ActivationEnd) || (event instanceof NavigationStart)),
    ).subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (event.navigationTrigger == 'popstate') {
          processNextActivation = true;
        }
      } else if (processNextActivation && (event instanceof ActivationEnd)) {
        processNextActivation = false;
        const params = event.snapshot.queryParams;

        if ('q' in params) {
          const terms = params['q'].split(',').map((value: string) => value.trim()).filter(((value: string) => value.length > 0));
          this.terms$.next(terms);
        } else { 
          this.terms$.next([]);
        }

        if ('selected' in params) {
          this.selectedService.selectById(params['selected']);
        } else {
          this.selectedService.selectById("");
        }

        if ('help' in params) {
          this.showHelpService.helpVisible.next(true);
        } else {
          this.showHelpService.helpVisible.next(false);
        }

        this.setupComplete$.next();
      }
    });

    combineLatest(
      {
        terms: this.terms$,
        selected: this.selectedService.selected,
        help: this.showHelpService.helpVisible
      }
    ).pipe(
      takeUntil(this.destroyed$),
      skipUntil(this.setupComplete$),
      debounceTime(100),
      distinctUntilChanged(),
    ).subscribe((e) => {
      const search = e.terms.length > 0 ? e.terms.join(",") : undefined;
      var help = e.help ? e.help : undefined;
      var selected = e.selected?.gene.id;
      const queryParams = {q: search, selected: selected, help: help};
      this.router.navigate(['/'], {queryParams: queryParams});
    });

  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  paste(event: ClipboardEvent) {
    event.preventDefault();
    const terms = this.terms$.getValue().slice();
    event.clipboardData
      ?.getData('Text')
      .split(/;|,|\n/)
      .forEach(value => {
        if (value.trim()) {
          terms.push(value.trim());
        }
      });

    this.termCtrl.setValue(null);

    this.terms$.next(terms);

    this.focusInput();
  }
  
  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add terms
    if (value) {
      const terms = this.terms$.getValue().slice()
      terms.push(value);
      this.terms$.next(terms);
    }

    event.chipInput!.clear();

    this.termCtrl.setValue(null);

    setTimeout(() => this.termInput.nativeElement.scrollIntoView(), 1);
  }

  remove(term: string): void {
    const terms = this.terms$.getValue().slice()
    const index = terms.indexOf(term);
    if (index >= 0) {
      terms.splice(index, 1);
      this.terms$.next(terms);
    }
  }

  removeAllTerms(): void {
    this.terms$.next([]);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const terms = this.terms$.getValue().slice()
    terms.push(event.option.viewValue);
    this.terms$.next(terms);
    
    this.termInput.nativeElement.value = '';
    this.termCtrl.setValue(null);
  }

  focusInput() {
    this.termInput.nativeElement.focus({preventScroll: false});
  }
  
  focus() {
    this.termInput.nativeElement.scrollIntoView(false);
  }

  private _filter(allTerms: string[], value: string): string[] {
    if (value.length < 2) {
      return [];
    }
    const filterValue = value.toLowerCase();
    return allTerms.filter(term => term.toLowerCase().includes(filterValue));
  }

  goHome() {
    this.removeAllTerms();
    this.selectedService.selectById("");
    this.showHelpService.helpVisible.next(false);
  }
}

function termArrayEquality(a: string[], b: string[]): boolean {
  if (a === b) {
    return true;
  }
  if (a.length != b.length) {
    return false;
  }

  for (var i=0; i<a.length; i++) {
    if (a[i] != b[i]) {
      return false;
    }
  }

  return true;
}