import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { Assay, BarSeqData, Timepoint } from '../gene';
import { clip } from '../statistics';
import { calculateGrayIntensity } from '../heatmap';

@Component({
  selector: 'app-phenotyping-graphs',
  standalone: false,
  templateUrl: './phenotyping-graphs.component.html',
  styleUrl: './phenotyping-graphs.component.scss',
})
export class PhenotypingGraphsComponent {
  private readonly destroyed$ = new Subject<void>();

  @Input() barseqData: BarSeqData;

  @ViewChild('downloadgraphs', {static: false}) downloadGraphs: ElementRef;

  // Min and max values of the log-transformed fitness phenotype values in the graphs
  public minMax = [-2.9, 1.3];

  // This generates an SVG path string from the cell line change values in given assay.
  assayAbundancesToPath(assay: Assay, parentals: boolean = false): string {
    const abundanceSelector = parentals ? 'medianParentalAbundance' : 'medianAbundance';
    const logData = assay.timepointList.filter((tp) => (!tp.isEmpty)).map((tp) => Math.log10(tp[abundanceSelector]));
    const N = logData.length;
    let ret = `M 0 ${this._y(0)} ` + logData.map((value, i) => `L ${(i+1)/N} ${this._y(value)}`).join(' ');
    return ret;
  }

  // Similar to above, this generates an SVG path string, but this time for filling, not stroke.
  assayAbundancesToFill(assay: Assay): string {
    const first = assay.timepointList.filter((tp) => (!tp.isEmpty)).map((tp) => Math.log10(tp.medianAbundance));
    const second = assay.timepointList.filter((tp) => (!tp.isEmpty)).map((tp) => Math.log10(tp.medianParentalAbundance));
    const N = first.length;
    let ret = `M 0 ${this._y(0)} ` + first.map((value, i) => `L ${(i+1)/N} ${this._y(value)}`).join(' ');
    ret += second.reverse().map((value, i) => ` L ${(N-i)/N} ${this._y(value)}`).join(" ");
    return ret;
  }

  // This transforms a change value to a graph y coordinate.
  _y(value: number): number {
    const max = this.minMax[1];
    const min = this.minMax[0];
    const extent = max - min;
    value = clip(value, min, max);
    return 1-(value - min)/extent;
  }

  // Generates a list of log10 y ticks 
  logYAxisTicks(): number[][] {
    const ticks: number[][] = [];
    for (var i = Math.ceil(this.minMax[0]); i <= this.minMax[1]; i++) {
      ticks.push([this._y(i), i]);
    }
    return ticks;
  } 

  // Generates the coordinates for the change value data points
  assayChangesPoints(assay: Assay): number[][] {
    const ret: number[][] = [[0, this._y(0)]];

    const max = this.minMax[1];
    const min = this.minMax[0];
    const extent = max - min;
    const N = assay.timepointList.length;

    Object.values(assay.timepoints)
      .forEach((tp: Timepoint, i) => {
        if (tp.abundance === undefined) return;
        const x = (i + 1)/N;
        tp.abundance.forEach((value) => {
          if (value > 0) {
            ret.push([x, this._y(Math.log10(value))]);
          }
        }
        )
      });
    return ret;
  }

  // This generates the colour gradient displayed between the parental and the cell line graph line
  assayGradientColors(assay: Assay): assayStyle[] {
    const N = assay.timepointList.length;
    return assay.timepointList.map((tp, i) => {
      var firstColor = "rgb(255, 0, 0)";
      var secondColor = "rgb(255, 255, 255)";
      if (tp.effectSize > 1) {
        firstColor = "rgb(0, 0, 255)";
        secondColor = "rgb(255, 255, 255)";
      }
      var grayIntensity = calculateGrayIntensity(tp.pValue);
      var colorIntensity = tp.effectSize > 0 ? Math.abs(Math.log2(tp.effectSize))/2 : 1;
      if (colorIntensity > 1) colorIntensity = 1;
      return {
        offset: `${Math.round(100*(i+1)/(N))}%`,
        colorPointX: `${Math.round(colorIntensity*100)}%`,
        firstColor: firstColor,
        secondColor: secondColor,
        grayIntensity: `${Math.round(grayIntensity*100)}%`,
      };
    })
  }

  originalOrder = (a: any, b: any): number => {
    return 0;
  }

  // Custom-format the time points in e.g. 1d, 3w, etc.
  formatTimepoint(tpString: any): string {
    const match = tpString.toLowerCase().match(/([0-9]+)([hdw]{0,1})/);
    if (match === null) {
      throw Error(`Malformatted timepoing "${tpString}".`);
    }

    var tp = Number(match[1]);
    const modifier = match[2];
    if ((modifier == '') || (modifier == 'h')) {
    } else if (modifier == 'd') {
      tp *= 24;
    } else if (modifier == 'w') {
      tp *= 7*24;
    }
    
    let suffix: string = 'h';
    let divider: number = 1;
    if (tp >= 7*24) {
      suffix = 'w';
      divider = 7*24;
    } else if (tp >= 24) {
      suffix = 'd';
      divider = 24;
    }
    return `${tp / divider}${suffix}`;
  }

  downloadSVGGraphs() {
    const serialiser = new XMLSerializer();
    const code = serialiser.serializeToString(this.downloadGraphs.nativeElement.childNodes[0])
    navigator.clipboard.writeText(code);
  }

  colorMix(style: assayStyle) {
    const base = [187, 187, 187];
    const p1 = parsePercent(style.grayIntensity)/100.0;
    const p2 = parsePercent(style.colorPointX)/100.0;
    const firstColor = CSSRgbToArray(style.firstColor);
    const secondColor = CSSRgbToArray(style.secondColor);
    let color = [0, 0, 0];
    for (let i=0; i < 3; i++) {
      color[i] = base[i] * p1 + (1-p1) * (firstColor[i] * p2 + secondColor[i] * (1-p2));
    }
    return `rgb(${color[0].toFixed(0)}, ${color[1].toFixed(0)}, ${color[2].toFixed(0)})`;
  }
}

function CSSRgbToArray(color: string): number[] {
  const m = /rgb\((?<R>[0-9]+), *(?<G>[0-9]+), *(?<B>[0-9]+)\)/.exec(color);
  if ((m !== null) && (m.groups !== undefined)) {
    return [Number(m.groups['R']), Number(m.groups['G']), Number(m.groups['B'])];
  }
  throw Error("Invalid color string.");
}

function parsePercent(percent: string): number {
  const m = /(?<percentage>[0-9]+)%/.exec(percent);
  if ((m !== null) && (m.groups !== undefined)) {
    return Number(m.groups['percentage']);
  }
  throw Error("Invalid percentage string.");
}

interface assayStyle {
  offset: string,
  grayIntensity: string,
  colorPointX: string,
  firstColor: string,
  secondColor: string,
}