<ng-container *ngIf="(TSNELocations | async) as locations">
<div class="lopit-graph" (dblclick)="downloadLopitSvg()">
<svg #lopitsvg viewBox="0 0 500 500">
    <style>
        #marker-gradient>stop {
            stop-color: black;
        }
        #marker-gradient-highlight-1>stop {
            stop-color: #f54db2;
        }
        #marker-gradient-highlight-2>stop {
            stop-color: #00d2a5;
        }
        #gene-marker-1>circle {
            fill: #f54db2;
        }
        #gene-marker-2>path:nth-child(1) {
            fill: #f54db2;
        }
        #gene-marker-2>path:nth-child(2) {
            fill: #00ff7f;
        }
    </style>
    <defs>
        <radialGradient id="marker-gradient">
            <stop offset="10%" stop-opacity="0.3"/>
            <stop offset="60%" stop-opacity="0.15"/>
            <stop offset="100%" stop-opacity="0"/>
        </radialGradient>
        <radialGradient id="marker-gradient-highlight-1" cx="0" cy="0" fr="0" gradientUnits="userSpaceOnUse">
            <stop offset="10%" stop-opacity="0.3"/>
            <stop offset="60%" stop-opacity="0.15"/>
            <stop offset="100%" stop-opacity="0"/>
        </radialGradient>
        <radialGradient id="marker-gradient-highlight-2" cx="0" cy="0" fr="0" gradientUnits="userSpaceOnUse">
            <stop offset="10%" stop-opacity="0.3"/>
            <stop offset="60%" stop-opacity="0.15"/>
            <stop offset="100%" stop-opacity="0"/>
        </radialGradient>
        <radialGradient id="marker-gradient-highlight-3" cx="0" cy="0" fr="0" gradientUnits="userSpaceOnUse">
            <stop offset="10%" stop-opacity="0.3"/>
            <stop offset="60%" stop-opacity="0.15"/>
            <stop offset="100%" stop-opacity="0"/>
        </radialGradient>
        <symbol id="gene-marker-highlight-0" viewBox="-1 -1 2 2">
            <circle r="1" fill="url(#marker-gradient)"/>
        </symbol>
        <symbol id="gene-marker-highlight-1" viewBox="-1 -1 2 2">
            <circle r="1" fill="url(#marker-gradient-highlight-1)"/>
        </symbol>
        <symbol id="gene-marker-highlight-2" viewBox="-1 -1 2 2">
            <path d="M 0 -1 V 1 A 1 1 0 1 0 0 -1" fill="url(#marker-gradient-highlight-1)"/>
            <path d="M 0 -1 V 1 A 1 1 0 1 1 0 -1" fill="url(#marker-gradient-highlight-2)"/>
        </symbol>
        <symbol id="gene-marker-highlight-3" viewBox="-1 -1 2 2">
            <path d="M 0 0 L -0.866 -0.5 A 1 1 0 0 1 0.866 -0.5" fill="url(#marker-gradient-highlight-1)"/>
            <path d="M 0 0 V 1 A 1 1 0 0 0 0.866 -0.5" fill="url(#marker-gradient-highlight-2)"/>
            <path d="M 0 0 V 1 A 1 1 0 0 1 -0.866 -0.5" fill="url(#marker-gradient-highlight-3)"/>
        </symbol>
        <symbol id="gene-marker-1" viewBox="-1 -1 2 2">
            <circle r="1" fill="green"/>
            <circle r="0.9" fill="none" stroke="black" stroke-width="0.2"/>
        </symbol>
        <symbol id="gene-marker-2" viewBox="-1 -1 2 2">
            <path d="M 0 -1 V 1 A 1 1 0 1 0 0 -1" fill="green"/>
            <path d="M 0 -1 V 1 A 1 1 0 1 1 0 -1" fill="red"/>
            <circle r="0.9" fill="none" stroke="black" stroke-width="0.2"/>
        </symbol>
        <symbol id="gene-marker-3" viewBox="-1 -1 2 2">
            <path d="M 0 0 L -0.866 -0.5 A 1 1 0 0 1 0.866 -0.5" fill="green"/>
            <path d="M 0 0 V 1 A 1 1 0 0 0 0.866 -0.5" fill="blue"/>
            <path d="M 0 0 V 1 A 1 1 0 0 1 -0.866 -0.5" fill="red"/>
            <circle r="0.9" fill="none" stroke="black" stroke-width="0.2"/>
        </symbol>
    </defs>
    <g id="markers" *ngIf="locations.get(gene.id)! as geneLoc">
        <use width="9" height="9"
            *ngFor="let marker of (locations | keyvalue);"
            [attr.href]="whichMarker(marker.value, geneLoc)"
            [attr.x]="marker.value.location.x" [attr.y]="marker.value.location.y"/>
    </g>
    <use
        *ngIf="locations.get(gene.id)! as geneLoc"
        [attr.href]="'#gene-marker-' + (geneLoc.terms.length > 0 ? geneLoc.terms.length : 1)"
        [attr.x]="geneLoc.location.x"
        [attr.y]="geneLoc.location.y"
        width="20" height="20"/>
</svg>
</div>
</ng-container>